import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-70df8826"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "group" }
const _hoisted_2 = { class: "menu-label" }
const _hoisted_3 = { class: "menu-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_aside_menu_item = _resolveComponent("aside-menu-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createElementVNode("ul", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createBlock(_component_aside_menu_item, {
          key: item.section,
          section: item.section,
          title: item.title,
          icon: item.icon
        }, null, 8, ["section", "title", "icon"]))
      }), 128))
    ])
  ]))
}