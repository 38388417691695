import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_upgrade_plan = _resolveComponent("upgrade-plan")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["modal", { 'is-active': _ctx.visible }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["modal-background animate__animated", _ctx.backgroundAnimation])
    }, null, 2),
    _createElementVNode("div", {
      class: _normalizeClass(["modal-content has-text-centered animate__animated", _ctx.cardAnimation])
    }, [
      _createVNode(_component_upgrade_plan, {
        class: "m-4",
        title: _ctx.title,
        description: _ctx.description,
        "is-embeded": true,
        laterButton: true,
        onOptionSelected: _ctx.close
      }, null, 8, ["title", "description", "onOptionSelected"])
    ], 2)
  ], 2))
}