<template>
  <modal-dialog ref="dialog">
    <template v-slot:content>
      <p
        class="is-size-5 mt-4 mb-2 has-text-weight-semibold animate__animated animate__fadeInDown animate__fast"
        v-html="title"
      ></p>
      <p
        class="is-size-6 mb-4 has-text-grey animate__animated animate__fadeInUp animate__fast"
        v-html="message"
      ></p>
      <hr />
      <div class="mt-5 is-centered buttons">
        <button
          v-for="(option, index) in options"
          :key="option"
          class="button animate__animated animate__fadeInUpSmall animate__delay-400ms animate__faster"
          :class="{ 'is-black': index === 0 }"
          @click="optionSelected(index)"
          v-html="option"
        >
        </button>
      </div>
    </template>
  </modal-dialog>
</template>

<script lang="ts">

import { Options, Vue } from 'vue-class-component'
import ModalDialog from './ModalDialog.vue'

@Options({
  components: {
    ModalDialog,
  },
})
export default class ModalConfirmation extends Vue {
  private title = ''
  private message = ''
  private options: string[] = []
  private resolve!: CallableFunction

  private static _instance: ModalConfirmation

  public static get Instance(): ModalConfirmation {
    return ModalConfirmation._instance
  }

  public created(): void {
    ModalConfirmation._instance = this
  }

  public ask(title: string, message: string, options: string[]): Promise<boolean | number> {
    // configure the dialog
    this.title = title
    this.message = message
    this.options = options;
    // display dialog
    (this.$refs.dialog as ModalDialog).show()
    // the response
    return new Promise<boolean | number>(resolve => {
      this.resolve = resolve
    })
  }

  public optionSelected(option: number): void {
    this.resolve(this.options.length > 2 ? option : option === 0);
    // hide dialog
    (this.$refs.dialog as ModalDialog).close()
  }
}

export function dialog(): ModalConfirmation {
  return ModalConfirmation.Instance
}
</script>