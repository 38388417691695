import { Type } from 'class-transformer'
import { Entity } from '@/models/Entities/Entity'
import { Price } from '@/models/Entities/Price'
import { Dish } from '@/models/Entities/Dish'

export class DishesGroup extends Entity {
    @Type(() => Price) public price!: Price | null
    @Type(() => Dish) public dishes!: Dish[]
    public title!: string
    public description!: string
    public minSelection = 1
    public maxSelection = 1

    constructor() {
        super()
        this.dishes = []
        this.price = null
    }

    public isOptional(): boolean {
        return this.price !== null && this.price.value > 0
    }

    public dishesIds(): string[] {
        const dishes: string[] = []
        this.dishes.forEach((dish: Dish) => {
            dishes.push(dish.id)
        })
        return dishes
    }
}