import { createStore } from 'vuex'

// declare your own store states
export type State = {
  /* NavBar */
  isNavBarVisible: boolean;
  /* Aside */
  isAsideVisible: boolean;
}

const state: State = {
  /* NavBar */
  isNavBarVisible: true,
  /* Aside */
  isAsideVisible: true,
}

export default createStore({
  state,
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
