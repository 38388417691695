import DateTransform from '@/models/DateTransform'

export enum SubscriptionPlan {
  Free = 'free',
  Basic = 'basic',
  Pro = 'pro',
  Premium = 'premium',
  PremiumPlus = 'premium-plus',
  HygienicOnly = 'hygienic-only',
}

export enum SubscriptionPlanLevel {
  Free = 0,
  HygienicOnly = 1,
  Basic = 2,
  Pro = 3,
  Premium = 4,
  PremiumPlus = 5,
}

export class SubscriptionDetails {
  public plan!: string // private?
  public paymentActive!: boolean

  @DateTransform() public startDate: Date | null = null
  @DateTransform() public endDate: Date | null = null
  @DateTransform() public paymentFailed: Date | null = null

  private planToStr(plan: string): string {
    switch (plan) {
      case SubscriptionPlan.Free:
        return 'Gratuito'
      case SubscriptionPlan.Basic:
        return 'Basic'
      case SubscriptionPlan.Pro:
        return 'Pro'
      case SubscriptionPlan.Premium:
        return 'Premium'
      case SubscriptionPlan.PremiumPlus:
        return 'Premium + Módulo Higiénico'
      case SubscriptionPlan.HygienicOnly:
        return 'Sólo módulo Higiénico'
    }
    return '-'
  }

  public get planStr(): string {
    return this.planToStr(this.thePlan())
  }

  public get realPlanStr(): string {
    return this.planToStr(this.plan)
  }

  public get startDateStr(): string {
    if (!this.startDate) return '-';
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' } as Intl.DateTimeFormatOptions
    const str = this.startDate.toLocaleDateString('es-ES', options)
    return `${ str[0].toUpperCase() }${ str.slice(1) }`
  }

  public get endDateStr(): string {
    if (!this.endDate) return '∞'
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' } as Intl.DateTimeFormatOptions
    const str = this.endDate.toLocaleDateString('es-ES', options)
    return `${ str[0].toUpperCase() }${ str.slice(1) }`
  }

  public get paymentFailedStr(): string {
    if (!this.paymentFailed) return '-';
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' } as Intl.DateTimeFormatOptions
    return this.paymentFailed.toLocaleDateString('es-ES', options)
  }

  public get daysLeft(): number {
    if (!this.endDate) return Infinity
    return Math.round((this.endDate.getTime() - (new Date()).getTime())/1000 / (60 * 60 * 24))
  }

  public isFreePlan(): boolean {
    return this.thePlan() === SubscriptionPlan.Free
  }

  public isExpired(): boolean {
    return this.endDate !== null && this.endDate.getTime() < (new Date()).getTime()
  }

  public thePlan(): string {
    if (this.isExpired()) {
      return SubscriptionPlan.Free
    }
    return this.plan
  }

  public planLevel(): number {
    switch (this.thePlan()) {
      case SubscriptionPlan.Free:
        return SubscriptionPlanLevel.Free
      case SubscriptionPlan.HygienicOnly:
        return SubscriptionPlanLevel.HygienicOnly
      case SubscriptionPlan.Basic:
        return SubscriptionPlanLevel.Basic
      case SubscriptionPlan.Pro:
        return SubscriptionPlanLevel.Pro
      case SubscriptionPlan.Premium:
        return SubscriptionPlanLevel.Premium
      case SubscriptionPlan.PremiumPlus:
        return SubscriptionPlanLevel.PremiumPlus
    }
    return 0
  }

  public maxMenusCountRestriction(): number {
    switch (this.thePlan()) {
      case SubscriptionPlan.Free:
      case SubscriptionPlan.Basic:
        return 1
      default:
        return Infinity
    }
  }

  public maxFixedMenusCountRestriction(): number {
    switch (this.thePlan()) {
      case SubscriptionPlan.Free:
      case SubscriptionPlan.Basic:
        return 0
      default:
        return Infinity
    }
  }

  public maxTablesCountRestriction(): number {
    switch (this.thePlan()) {
      case SubscriptionPlan.Free:
      case SubscriptionPlan.Basic:
      case SubscriptionPlan.Pro:
        return 0
      default:
        return Infinity
    }
  }

  public ordersAvailable(): boolean {
    return this.planLevel() >= SubscriptionPlanLevel.Premium
  }

  public customLogoAvailable(): boolean {
    return this.planLevel() >= SubscriptionPlanLevel.Basic
  }

  public onlinePaymentsAvailable(): boolean {
    return this.planLevel() >= SubscriptionPlanLevel.Premium
  }

  public salesChannelsAvailable(): boolean {
    return this.planLevel() >= SubscriptionPlanLevel.Premium
  }

  public printerSettingsAvailable(): boolean {
    return this.planLevel() >= SubscriptionPlanLevel.Premium
  }
}