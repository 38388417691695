<template>
  <li>
    <router-link
      :to="'/' + section"
      :class="{ 'is-active': isActive(), 'has-icon': icon !== null }"
    >
      <span
        v-if="icon !== null"
        class="icon"
      ><i :class="icon"></i></span>
      <span class="menu-item-label">{{ title }}</span>
    </router-link>
  </li>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

export default class AsideMenuItem extends Vue {
  @Prop({ default: null }) private title!: string | null
  @Prop({ default: null }) private icon!: string | null
  @Prop({ default: null }) private section!: string | null

  private isActive(): boolean {
    return this.section ? this.$route.path.startsWith('/' + this.section) : false
  }
}
</script>