import { Type } from 'class-transformer'
import { Server } from '@/models/Server'
import { Price } from '@/models/Entities/Price'
import { Menu } from '@/models/Entities/Menu'

export class FixedMenu extends Menu {
    @Type(() => Price) public price!: Price

    public constructor() {
        super()
        this.price = new Price()
    }

    public photoUrl(): string {
        if (this.photo) {
            return Server.Instance.resourceUrl('photo/fixed-menu/' + this.id + '?nc=' + this.photo.id);
        }
        return require('@/assets/images/common/dish-no-photo.svg');
    }
}