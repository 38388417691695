import { FixedMenu } from '@/models/Entities/FixedMenu'
import { OrderSelection, OrderSelectionDish } from '@/models/Entities/OrderSelection'
import { Price } from '@/models/Entities/Price'
import { Restaurant } from '@/models/Entities/Restaurant'
import { plainToInstance, Type } from 'class-transformer'

export const OrderFixedMenuSelectionType = 'fixed-menu-line'

export class OrderFixedMenuDishSelection extends OrderSelectionDish {

  public clone(): OrderFixedMenuDishSelection {
    return plainToInstance(OrderFixedMenuDishSelection, this)
  }
}

export class OrderFixedMenuSelection extends OrderSelection {
  @Type(() => String) public type = OrderFixedMenuSelectionType
  @Type(() => String) public fixedMenuId: string
  @Type(() => OrderFixedMenuDishSelection) public selection: OrderFixedMenuDishSelection[]

  private _fixedMenu!: FixedMenu

  constructor(fixedMenuId: string, selection: OrderFixedMenuDishSelection[], quantity: number) {
    super()
    this.fixedMenuId = fixedMenuId
    this.selection = selection ?? []
    this.quantity = quantity
    // auto-assign the title and unit-price
    if (fixedMenuId) {
      this.title = this.fixedMenu.title
      this.unitPrice = this.fixedMenu.price
    }
  }

  public get fixedMenu(): FixedMenu {
    if (!this._fixedMenu) {
      this._fixedMenu = Restaurant.Instance.getFixedMenu(this.fixedMenuId) as FixedMenu
    }
    return this._fixedMenu
  }

  public totalPricePreview(): Price {
    const price = new Price(this.fixedMenu.price)
    for (let index = 0; index < this.selection.length; index++) {
      const groupPrice = this.selection[index].group.price
      if (groupPrice && groupPrice.value > 0) price.value += groupPrice.value
    }
    price.value *= this.quantity
    return price
  }

  public canBeOrdered(): boolean {
    if (this.fixedMenu) {
      for (let index = 0; index < this.fixedMenu.dishesGroups.length; index++) {
        if (this.fixedMenu.dishesGroups[index].isOptional()) continue
        if (!this.selectionByGroup(this.fixedMenu.dishesGroups[index])) return false
      }
      return true
    }
    return false
  }

  public clone(): OrderFixedMenuSelection {
    return plainToInstance(OrderFixedMenuSelection, this)
  }
}