import { Entity } from '@/models/Entities/Entity'
import { Type } from 'class-transformer'
import { Server } from '../Server'
import { DishesGroup } from './DishesGroup'
import { Photo } from './Photo'

export enum MenuStyle {
  PhotoGrid = 1,
  TextList = 2,
}

export class Menu extends Entity {
  @Type(() => Photo) public photo!: Photo
  @Type(() => DishesGroup) public dishesGroups!: DishesGroup[]
  public active = false
  public title!: string
  public description!: string
  public remoteDisabled = false
  public style = MenuStyle.PhotoGrid

  public get orderingEnabled(): boolean {
    return !this.remoteDisabled
  }

  public set orderingEnabled(value: boolean) {
    this.remoteDisabled = !value
  }

  public addDishesGroup(group: DishesGroup): void {
    if (!this.dishesGroups) this.dishesGroups = []
    this.dishesGroups.push(group)
  }

  public deleteDishesGroup(group: DishesGroup): void {
    if (this.dishesGroups && this.dishesGroups.length > 0) {
      const index = this.dishesGroups.indexOf(group)
      this.dishesGroups.splice(index, 1)
    }
  }

  public dishesGroupById(id: string): DishesGroup | null {
    return this.dishesGroups.find(e => e.id === id) ?? null
  }

  public photoUrl(): string {
    if (this.photo) {
      return Server.Instance.resourceUrl('photo/menu/' + this.id + '?nc=' + this.photo.id)
    }
    return require('@/assets/images/common/dish-no-photo.svg')
  }
}