import { Server } from '../Server'
import { BasicSettings } from './BasicSettings'
import { Dish } from './Dish'
import { DishesGroup } from './DishesGroup'
import { FixedMenu } from './FixedMenu'
import { Menu } from './Menu'
import { Tables } from './Tables'

export class Restaurant {
  public menus!: Menu[]
  public fixedMenus!: FixedMenu[]
  public tables!: Tables[]
  public favoriteDishes!: Dish[]
  public basicSettings!: BasicSettings

  private _cachedDishes: Record<string, Dish> = {}
  private _cachedDishesGroup: Record<string, DishesGroup> = {}
  private _cachedTables: Record<string, Tables> = {}

  private static _instance: Restaurant

  public static get Instance(): Restaurant {
    return this._instance || (this._instance = new this())
  }

  public update(): Promise<void> {
    return new Promise<void>(resolve => {
      const a = Server.Instance.allMenus()
      const b = Server.Instance.allFixedMenus()
      const c = Server.Instance.allTables()
      const d = Server.Instance.allFavoriteDishes()
      const e = Server.Instance.basicSettings()
      // wait until all requests finished
      Promise.all([a, b, c, d, e]).then((values) => {
        this.menus = values[0]
        this.fixedMenus = values[1]
        this.tables = values[2]
        this.favoriteDishes = values[3]
        this.basicSettings = values[4]
        resolve()
      })
    })
  }

  public getMenu(id: string): Menu | null {
    return this.menus.find(e => e.id === id) ?? null
  }

  public getFixedMenu(id: string): FixedMenu | null {
    return this.fixedMenus.find(e => e.id === id) ?? null
  }

  private findDishesGroup(id: string, dishesGroups: DishesGroup[]): DishesGroup | null {
    // look in initial groups
    let group = dishesGroups.find(e => e.id === id)
    if (group) {
      return group
    }
    // look into dishes (from initial groups)
    for (let index = 0; index < dishesGroups.length; index++) {
      group = dishesGroups[index]
      for (let dishIndex = 0; dishIndex < group.dishes.length; dishIndex++) {
        const dish = group.dishes[dishIndex]
        if (dish.hasComplements()) {
          const result = this.findDishesGroup(id, dish.dishesGroups)
          if (result) {
            return result
          }
        }
      }
    }
    return null
  }

  public getDishesGroup(id: string): DishesGroup | null {
    if (this._cachedDishesGroup[id]) {
      return this._cachedDishesGroup[id];
    }
    // look into menus
    for (let index = 0; index < this.menus.length; index++) {
      const group = this.findDishesGroup(id, this.menus[index].dishesGroups ?? [])
      if (group) {
        this._cachedDishesGroup[id] = group
        return group
      }
    }
    // look into fixed-menus
    for (let index = 0; index < this.fixedMenus.length; index++) {
      const group = this.findDishesGroup(id, this.fixedMenus[index].dishesGroups ?? [])
      if (group) {
        this._cachedDishesGroup[id] = group
        return group
      }
    }
    // look into favorites
    for (let index = 0; index < this.favoriteDishes.length; index++) {
      const group = this.findDishesGroup(id, this.favoriteDishes[index].dishesGroups ?? [])
      if (group) {
        this._cachedDishesGroup[id] = group
        return group
      }
    }
    return null;
  }


  public getDish(id: string): Dish | null {
    if (this._cachedDishes[id]) {
      return this._cachedDishes[id];
    }
    // look into menus
    for (let index = 0; index < this.menus.length; index++) {
      const dish = this.findDishInDishesGroups(id, this.menus[index].dishesGroups)
      if (dish) {
        this._cachedDishes[id] = dish
        return dish
      }
    }
    // look into fixed-menus
    for (let index = 0; index < this.fixedMenus.length; index++) {
      const dish = this.findDishInDishesGroups(id, this.fixedMenus[index].dishesGroups)
      if (dish) {
        this._cachedDishes[id] = dish
        return dish
      }
    }
    // look into favorites
    for (let index = 0; index < this.favoriteDishes.length; index++) {
      const theDish = this.favoriteDishes[index]
      const dish = theDish.id === id ? theDish : this.findDishInDishesGroups(id, theDish.dishesGroups ?? [])
      if (dish) {
        this._cachedDishes[id] = dish
        return dish
      }
    }
    return null;
  }

  public getTables(id: string): Tables | null {
    if (this._cachedTables[id]) {
      return this._cachedTables[id]
    }
    for (let index = 0; index < this.tables.length; index++) {
      const tables = this.tables[index]
      if (tables.id === id) {
        this._cachedTables[id] = tables
        return tables
      }
    }
    return null
  }

  private findDishInDishesGroups(id: string, groups: DishesGroup[]): Dish | null {
    if (groups) {
      for (let index = 0; index < groups.length; index++) {
        for (let dishIndex = 0; dishIndex < groups[index].dishes.length; dishIndex++) {
          const dish = groups[index].dishes[dishIndex]
          if (dish.id === id) return dish
          if (dish.hasComplements()) {
            const result = this.findDishInDishesGroups(id, dish.dishesGroups)
            if (result) return result
          }
        }
      }
    }
    return null;
  }

  public hasActiveTables(): boolean {
    return this.tables !== undefined && this.tables !== null && this.tables.length > 0
  }

  public activeTables(): Tables[] {
    const result = []
    if (this.hasActiveTables()) {
      for (let index = 0; index < this.tables.length; index++) {
        if (this.tables[index].active)
          result.push(this.tables[index])
      }
    }
    return result
  }

  public hasMenus(): boolean {
    return this.menus !== undefined && this.menus !== null && this.menus.length > 0
  }

  public activeMenus(): Menu[] {
    const result = []
    if (this.hasMenus()) {
      for (let index = 0; index < this.menus.length; index++) {
        if (this.menus[index].active)
          result.push(this.menus[index])
      }
    }
    return result
  }

  public hasFixedMenus(): boolean {
    return this.fixedMenus !== undefined && this.fixedMenus !== null && this.fixedMenus.length > 0
  }

  public activeFixedMenus(): FixedMenu[] {
    const result = []
    if (this.hasFixedMenus()) {
      for (let index = 0; index < this.fixedMenus.length; index++) {
        if (this.fixedMenus[index].active)
          result.push(this.fixedMenus[index])
      }
    }
    return result
  }
}
