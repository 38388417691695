<template>
  <div class="group">
    <p class="menu-label">{{ title }}</p>
    <ul class="menu-list">
      <template
        v-for="item in items"
        :key="item.section"
      >
        <aside-menu-item
          :section="item.section"
          :title="item.title"
          :icon="item.icon"
        />
      </template>
    </ul>
  </div>
</template>

<script lang="ts">
import AsideMenuItem from '@/components/AsideMenuItem.vue'
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Options({
  components: {
    AsideMenuItem,
  },
})
export default class AsideMenuList extends Vue {
  @Prop({ default: null }) private title!: string | null
  @Prop({ default: [] }) private items!: []
}
</script>

<style
  lang="scss"
  scoped
>
.group {
  padding-left: env(safe-area-inset-left);
}
</style>