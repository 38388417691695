import { Type } from 'class-transformer'
import { AccountData } from './AccountData'
import { Photo } from './Entities/Photo'
import { SubscriptionDetails } from './Entities/SubscriptionDetails'
import { Server } from './Server'

export class BasicAccountData {
  @Type(() => Photo) public logo!: Photo
  @Type(() => SubscriptionDetails) public subscription!: SubscriptionDetails
  @Type(() => String) public name!: string
  @Type(() => String) public color!: string
  @Type(() => Boolean) public cloudPrinting!: boolean

  public logoUrl(small: boolean): string {
    if (this.logo && !small) {
      return Server.Instance.resourceUrl('me/profile?nc=' + this.logo.id)
    }
    // customize color
    const color = this.color ? '&b=%23' + this.color : ''
    return 'https://api.dicebear.com/7.x/initials/svg?seed=' + encodeURI(this.name) + '&backgroundColorLevel=500&fontSize=37&bold=true' + color
  }

  public updateFromAccount(account: AccountData): void {
    this.logo = account.logo
    this.color = account.color
    this.name = account.name
  }
}
