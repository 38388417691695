import { Type } from "class-transformer"
import { Price } from "./Price"

export class SalesChannels {
    @Type(() => Boolean) public inSitu!: boolean
    @Type(() => Boolean) public takeaway!: boolean
    @Type(() => Boolean) public delivery!: boolean
    @Type(() => Price) public minimumOrderPrice: Price = new Price()
    @Type(() => Price) public shippingCostPrice: Price = new Price()
    @Type(() => String) public shippingZones: string[] = []
}