import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ background: !_ctx.isEmbeded })
  }, [
    _createElementVNode("section", {
      class: _normalizeClass({ 'section hero is-fullheight is-error-section': !_ctx.isEmbeded })
    }, [
      _createElementVNode("div", {
        class: _normalizeClass({ 'hero-body': !_ctx.isEmbeded })
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["has-text-centered", { container: !_ctx.isEmbeded }])
        }, [
          _createElementVNode("p", {
            class: _normalizeClass(["is-size-3 has-text-weight-bold animate__animated animate__fadeInDown animate__fast", { 'is-size-4': _ctx.isSmall }])
          }, _toDisplayString(_ctx.title), 3),
          _createElementVNode("i", {
            class: _normalizeClass(["fal fa-lock-alt mt-3 mb-5 animate__animated animate__tada animate__fast", { 'fa-4x': _ctx.isSmall, 'fa-5x': !_ctx.isSmall }])
          }, null, 2),
          _createElementVNode("p", {
            class: _normalizeClass(["is-size-5 description animate__animated animate__fadeInUp animate__fast", { 'is-size-6': _ctx.isSmall }])
          }, _toDisplayString(_ctx.description), 3),
          _createElementVNode("button", {
            class: "button is-black mt-6 animate__animated animate__fadeInUpSmall animate__delay-500ms",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.upgrade()))
          }, " Actualizar mi plan "),
          (_ctx.laterButton)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "button mt-6 ml-3 animate__animated animate__fadeInUpSmall animate__delay-500ms",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('option-selected', 2)))
              }, " Más tarde "))
            : _createCommentVNode("", true)
        ], 2)
      ], 2)
    ], 2)
  ], 2))
}