<template>
  <nav
    v-show="$store.state.isNavBarVisible"
    id="navbar-main"
    class="navbar is-fixed-top"
  >
    <div class="navbar-brand">
      <a
        class="navbar-item is-navbar-toggle"
        :class="{ 'is-expanded': asideMenuExpanded }"
        @click.prevent="toggleExpandMenu()"
      >
        <span class="icon">
          <i
            class="far fa-chevron-double-down"
            :class="expandButtonIconDirection()"
          ></i>
        </span>
      </a>
      <div class="navbar-item has-control no-left-space-touch">
        <div class="control">
          <!--<input class="input" placeholder="Buscar…" />-->
        </div>
      </div>
    </div>
    <div class="navbar-brand is-right">
      <a
        class="navbar-item navbar-item-menu-toggle is-hidden-desktop"
        @click="toggleMeMenu()"
      >
        <i class="fal fa-bars"></i>
      </a>
    </div>
    <div
      class="navbar-menu animate__animated animate__faster"
      :class="{ 'is-active': meMenuActive }"
    >
      <div class="navbar-end">
        <div
          v-if="me"
          class="
            navbar-item
            has-dropdown has-dropdown-with-icons has-divider has-user-avatar
          "
          :class="{ 'is-active': meMenuActive }"
          @click="toggleMeMenu()"
        >
          <a
            class="navbar-link is-arrowless"
          >
            <div class="is-user-avatar">
              <img
                :src="me.logoUrl(true)"
                :alt="me.name"
              />
            </div>
            <div class="is-user-name">
              <span>{{ me.name }}</span>
            </div>
            <span class="icon ml-1">
              <i
                v-if="meMenuActive"
                class="fal fa-chevron-up"
              ></i>
              <i
                v-else
                class="fal fa-chevron-down"
              ></i>
            </span>
          </a>
          <div class="navbar-dropdown">
            <router-link
              :to="'/profile'"
              class="navbar-item"
            >
              <span class="icon"><i class="fal fa-id-card-alt"></i></span>
              <span>Mi perfil</span>
            </router-link>
            <router-link
              :to="'/settings'"
              class="navbar-item"
            >
              <span class="icon"><i class="fal fa-cog"></i></span>
              <span>Preferencias</span>
            </router-link>
          </div>
        </div>
        <a
          class="navbar-item has-divider is-desktop-icon-only"
          title="Dark mode"
          @click="darkModeToggle()"
        >
          <i :class="darkModeButtonIcon()"></i>
          <span class="ml-3">Dark mode</span>
        </a>
        <a
          class="navbar-item has-divider is-desktop-icon-only"
          title="About"
          href="https://www.mrwaiter.com/tutoriales/"
          target="_blank"
        >
          <i class="fal fa-question-circle"></i>
          <span class="ml-3">About</span>
        </a>
        <a
          class="navbar-item is-desktop-icon-only"
          title="Log out"
          @click="logout()"
        >
          <i class="fal fa-sign-out"></i>
          <span class="ml-3">Log out</span>
        </a>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">

import { BasicAccountData } from '@/models/BasicAccountData'
import { LocalStorage } from '@/models/LocalStorage'
import { Observer, ObserverEvents } from '@/models/Observer'
import { Vue } from 'vue-class-component'

export default class NavBar extends Vue {
  private asideMenuExpanded = LocalStorage.Instance.asideMenuExpanded
  private darkModeActive = LocalStorage.Instance.darkMode
  private meMenuActive = false
  private me: BasicAccountData | null = null

  public mounted(): void {
    this.me = LocalStorage.Instance.me
    // look for changes
    Observer.Instance.subscribe(ObserverEvents.ProfileChanged, () => {
      this.me = LocalStorage.Instance.me
      this.$forceUpdate()
    })
    Observer.Instance.subscribe(ObserverEvents.MenuExpandStateChanged, (expanded: boolean) => {
      this.asideMenuExpanded = expanded
    })
  }

  private logout(): void {
    Observer.Instance.publish(ObserverEvents.LogoutRequested)
  }

  private toggleMeMenu(): void {
    this.meMenuActive = !this.meMenuActive
  }

  private toggleExpandMenu(): void {
    this.meMenuActive = false
    Observer.Instance.publish(ObserverEvents.ToggleExpandMenu)
  }

  private darkModeToggle(): void {
    this.meMenuActive = false
    this.darkModeActive = !this.darkModeActive
    Observer.Instance.publish(ObserverEvents.ToggleDarknight)
  }

  private expandButtonIconDirection(): string {
    return this.asideMenuExpanded ? 'fa-rotate-90' : 'fa-rotate-270'
  }

  private darkModeButtonIcon(): string {
    return this.darkModeActive ? 'fas fa-sun' : 'fal fa-moon-stars'
  }
}
</script>

<style
  lang="scss"
  scoped
>
.is-fixed-top {
  padding-left: env(safe-area-inset-left);
  padding-top: env(safe-area-inset-top);
  padding-right: env(safe-area-inset-right);
}

.navbar-menu {
  margin-top: env(safe-area-inset-top);
}

.navbar-end {
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}
</style>