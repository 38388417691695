import { BasicAccountData } from '@/models/BasicAccountData'
import { Observer, ObserverEvents } from '@/models/Observer'
import { plainToClass } from 'class-transformer'

export class LocalStorage {

  private static _instance: LocalStorage

  public static get Instance(): LocalStorage {
    return this._instance || (this._instance = new this())
  }

  // account data

  private _me!: BasicAccountData | null

  public get me(): BasicAccountData | null {
    if (this._me === undefined) {
      const data = localStorage.getItem('me')
      if (data) this._me = plainToClass(BasicAccountData, JSON.parse(data))
    }
    return this._me
  }

  public set me(account: BasicAccountData | null) {
    if (account) {
      // store information
      localStorage.setItem('me', JSON.stringify(account))
    } else {
      localStorage.removeItem('me')
    }
    // update memory value
    this._me = account
    // change notification
    Observer.Instance.publish(ObserverEvents.ProfileChanged)
  }

  public set darkMode(darkMode: boolean) {
    localStorage.setItem('darkMode', JSON.stringify(darkMode))
  }

  public get darkMode(): boolean {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)')
    const data = localStorage.getItem('darkMode')
    return data ? JSON.parse(data) : prefersDark.matches
  }

  public set asideMenuExpanded(expanded: boolean) {
    localStorage.setItem('asideMenuExpanded', JSON.stringify(expanded))
  }

  public get asideMenuExpanded(): boolean {
    const data = localStorage.getItem('asideMenuExpanded')
    return data ? JSON.parse(data) : false
  }
}
