import DateTransform from '@/models/DateTransform'
import { Entity } from '@/models/Entities/Entity'
import { Price } from '@/models/Entities/Price'
import { Type } from 'class-transformer'

export class OrderPayment extends Entity {
  @Type(() => Price) public amount!: Price
  @DateTransform() public date!: Date
  public type!: string
  public linesIds: string[] = []
}
