import { Dish } from '@/models/Entities/Dish'
import { DishesGroup } from '@/models/Entities/DishesGroup'
import { Entity } from '@/models/Entities/Entity'
import { OrderFixedMenuDishSelection } from '@/models/Entities/OrderFixedMenuSelection'
import { Price } from '@/models/Entities/Price'
import { Restaurant } from '@/models/Entities/Restaurant'
import { plainToInstance, Type } from 'class-transformer'

export abstract class OrderSelectionDish {
  @Type(() => String) public id!: string
  @Type(() => String) public groupId: string
  @Type(() => String) public dishId: string
  @Type(() => String) public notes: string | null
  @Type(() => Boolean) public served = false
  @Type(() => String) public title!: string
  @Type(() => Price) public price: Price | null = null

  private _group!: DishesGroup
  private _dish!: Dish

  constructor(groupId: string, dishId: string, notes: string | null) {
    this.groupId = groupId
    this.dishId = dishId
    this.notes = notes
  }

  public get group(): DishesGroup {
    if (!this._group) {
      this._group = Restaurant.Instance.getDishesGroup(this.groupId) as DishesGroup
    }
    return this._group
  }

  public get dish(): Dish {
    if (!this._dish) {
      this._dish = Restaurant.Instance.getDish(this.dishId) as Dish
    }
    return this._dish
  }

  public isOptional(): boolean {
    return this.price !== null && this.price.value > 0
  }

  public abstract clone(): OrderSelectionDish;
}

export class OrderSelection extends Entity {
  @Type(() => String) public type = ''
  @Type(() => Boolean) public confirmed = false
  @Type(() => Boolean) public served = false
  @Type(() => String) public payment: string | null = null
  @Type(() => Number) public quantity = 0
  @Type(() => String) public title = ''
  @Type(() => Price) public unitPrice = new Price()
  @Type(() => OrderSelectionDish) public selection: OrderSelectionDish[] = []

  public get paid(): boolean {
    return this.payment !== null
  }

  public totalPrice(): Price {
    const price = new Price(this.unitPrice)
    price.value *= this.quantity
    return price
  }

  public get partialServed(): boolean {
    if (this.selection && this.selection.length > 0) {
      let ok = 0
      let nok = 0
      for (let index = 0; index < this.selection.length; index++) {
        if (this.selection[index].served) {
          ok++
        } else {
          nok++
        }
      }
      return !(ok === this.selection.length || nok === this.selection.length)
    }
    return false
  }

  public selectionByGroup(group: DishesGroup): OrderFixedMenuDishSelection | null {
    return this.selection.find(e => e.group.id === group.id) ?? null
  }

  public get allServed(): boolean {
    if (this.selection && this.selection.length > 0) {
      for (let index = 0; index < this.selection.length; index++) {
        if (!this.selection[index].served) return false
      }
    }
    return this.served
  }

  public hasSelection(): boolean {
    return this.selection && this.selection.length > 0
  }

  public clone(): OrderSelection {
    // warning: this method should be implemented in child classes!
    return plainToInstance(OrderSelection, this)
  }
}