<template>
  <div
    class="modal"
    :class="{ 'is-active': visible }"
  >
    <div
      class="modal-background animate__animated"
      :class="backgroundAnimation"
    ></div>
    <div
      class="modal-content has-text-centered animate__animated"
      :class="cardAnimation"
    >
      <slot name="content" />
    </div>
  </div>
</template>

<script lang="ts">

import { Observer, ObserverEvents } from '@/models/Observer'
import { Vue } from 'vue-class-component'

export default class ModalDialog extends Vue {
  protected visible = false

  protected hideTimeout = 0
  protected backgroundAnimation = ''
  protected cardAnimation = ''

  public show(): void {
    debugger
    // cancel the previous hide time-out
    if (this.hideTimeout > 0) {
      clearTimeout(this.hideTimeout)
      this.hideTimeout = 0
    }
    // display the dialog
    this.visible = true
    // display animated
    this.backgroundAnimation = 'animate__fadeIn'
    this.cardAnimation = 'animate__fadeInUpSmall animate__faster'
    // publish this fact
    Observer.Instance.publish(ObserverEvents.ModalDialogActivated)
}

  public close(): void {
    // hide view
    this.backgroundAnimation = 'animate__fadeOut'
    this.cardAnimation = 'animate__fadeOutDownSmall animate__faster'
    // pubish this fact
    Observer.Instance.publish(ObserverEvents.ModalDialogDesactivated)
    this.hideTimeout = setTimeout(() => {
      this.visible = false
    }, 850)
  }
}
</script>

<style
  lang="scss"
  scoped
>
.modal-card {
  width: 40vw !important;
}

@media screen and (max-width: 1200px) {
  .modal-card {
    width: 70vw !important;
  }
}

@media screen and (max-width: 900px) {
  .modal-card {
    width: 90vw !important;
  }
}

$padding: 1.45rem;

.modal-content {
  box-shadow: 0px 10px 30px 5px rgba($color: #000000, $alpha: 0.15);
  background-color: white !important;
  border-radius: 25px !important;
  padding: $padding !important;

  @media (max-width: 1024px) {
    width: 88%;
  }
}

html {
  &.is-dark-mode-active {
    .modal-content {
      background-color: #242424 !important;
    }
  }
}

</style>
