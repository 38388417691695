/* eslint-disable no-console */

import { Capacitor } from '@capacitor/core'
import { register } from 'register-service-worker'
import { Observer, ObserverEvents } from './models/Observer'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
      // stupid hack to "solve the Android Plugin not registered" on startup
      if (Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'android') {
        const reboot = localStorage.getItem('reboot')
        if (reboot !== '1') {
          localStorage.setItem('reboot', '1')
          setTimeout(() => location.reload(), 1)
        } else {
          localStorage.setItem('reboot', '0')
        }
      }
    },
    registered() {
      console.log('Service worker has been registered.')
    },
    cached() {
      console.log('Content has been cached for offline use.')
      // hide the loading view
      Observer.Instance.publish(ObserverEvents.AppUpdateFinished)
    },
    updatefound() {
      console.log('New content is downloading.')
      // display the loading
      Observer.Instance.publish(ObserverEvents.AppUpdateFound)
    },
    updated(registration: ServiceWorkerRegistration) {
      console.log('New content is available; please refresh.')
      // prevent an infinite reload (the new worker will be now the primary one)
      registration?.waiting?.postMessage({ type: 'SKIP_WAITING' })
      // automatically refresh the page
      location.reload()
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
