import { AccountData } from '@/models/AccountData'
import { PUBLIC_API_BASE_URL } from '@/models/Consts'
import { Allergen } from '@/models/Entities/Allergen'
import { Category } from '@/models/Entities/Category'
import { Dish } from '@/models/Entities/Dish'
import { DishesGroup } from '@/models/Entities/DishesGroup'
import { Entity } from '@/models/Entities/Entity'
import { FixedMenu } from '@/models/Entities/FixedMenu'
import { LiveOptions } from '@/models/Entities/LiveOptions'
import { Menu } from '@/models/Entities/Menu'
import { OnlinePayments } from '@/models/Entities/OnlinePayments'
import { Order } from '@/models/Entities/Order'
import { OrderSelection, OrderSelectionDish } from '@/models/Entities/OrderSelection'
import { Photo } from '@/models/Entities/Photo'
import { PrinterSettings } from '@/models/Entities/PrinterSettings'
import { Promo } from '@/models/Entities/Promo'
import { SubscriptionDetails } from '@/models/Entities/SubscriptionDetails'
import { SubscriptionPlanItem } from '@/models/Entities/SubscriptionPlanItem'
import { Tables } from '@/models/Entities/Tables'
import { LocalStorage } from '@/models/LocalStorage'
import { LoginResult, LoginResultCode } from '@/models/LoginResult'
import { Observer, ObserverEvents } from '@/models/Observer'
import { ServerRequest } from '@/models/ServerRequest'
import { ServerResponse } from '@/models/ServerResponse'
import axios from 'axios'
import { instanceToPlain, plainToInstance } from 'class-transformer'
import { ClassConstructor } from 'class-transformer/types/interfaces'
import clone from 'fast-clone'
import { BasicAccountData } from './BasicAccountData'
import { BasicSettings } from './Entities/BasicSettings'
import { ChefCall } from './Entities/ChefCall'
import { OrderDishSelection } from './Entities/OrderDishSelection'
import { OrderFixedMenuSelection } from './Entities/OrderFixedMenuSelection'
import { OrderPayment } from './Entities/OrderPayment'
import { OrdersFilterOptions } from './Entities/OrdersFilterOptions'
import { PayCall } from './Entities/PayCall'
import { PaymentMethods } from './Entities/PaymentMethods'
import { SalesChannels } from './Entities/SalesChannels'
import { FinishedOrdersResult } from './FinishedOrdersResult'

export enum ServerErrorCodes {
  // common error codes
  MissingSessionAuth = 'ERR_MISSING_SESSION_AUTH',
  // session error codes
  InvalidSessionAuth = 'ERR_INVALID_SESSION_AUTH',
  SessionForbiddenAccess = 'ERR_FORBIDDEN_ACCESS',
  // save errors
  RequiredPropertiesMissing = 'ERR_REQUIRED_PROPERTIES_MISSING',
  // deletion error
  DeletingResourceInUse = 'ERR_TRYING_DELETE_RESOURCE_IN_USE',
  DeletingResourceWithoutConfirmation = 'ERR_TRYING_DELETE_RESOURCE_IN_USE_WITHOUT_CONFIRMATION',
  // sign-up errors
  AliasAlreadyInUse = 'ERR_ALIAS_ALREADY_IN_USE',
  EmailAlreadyInUse = 'ERR_EMAIL_ALREADY_IN_USE',
  PhoneNumberIsNotValid = 'ERR_PHONE_NUMBER_IS_NOT_VALID',
  PasswordIsTooShort = 'ERR_PASSWORD_IS_TOO_SHORT',
  // plan errors
  PlanLimitReached = 'ERR_PLAN_LIMIT_REACHED',
}

const API_URL_PATH = 'api/v1/'

export class Server {
  private static _instance: Server

  public static get Instance(): Server {
    return this._instance || (this._instance = new this())
  }

  constructor() {
    // on prepare request
    axios.interceptors.request.use(
      config => {
        config.withCredentials = true
        return config
      },
      error => {
        return Promise.reject(error)
      },
    )
    // on parse response
    axios.interceptors.response.use(
      response => {
        if (response.headers['response-type'] === 'api-response') {
          const apiResponse = plainToInstance(ServerResponse, response.data)
          response.data = apiResponse
          // check for login
          if (!apiResponse.success) {
            switch (apiResponse.code) {
              case ServerErrorCodes.MissingSessionAuth:
                response.data.handled = true
                Observer.Instance.publish(ObserverEvents.LoginRequired)
                break
            }
          }
        }
        return response
      },
      error => {
        if (!error.response) {
          Observer.Instance.publish(ObserverEvents.ServerConnectionError)
        }
        return Promise.reject(error)
      },
    )
  }

  private randomId(length: number): string {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  // HTTP methods

  private GET(method: string): Promise<ServerResponse> {
    return new Promise<ServerResponse>(resolve => {
      const nr = this.randomId(5)
      axios.get(`${ PUBLIC_API_BASE_URL }${ API_URL_PATH }${ method }?nr=${ nr }`).then(response => {
        resolve(response.data)
      })
    })
  }

  private POST(method: string, request: ServerRequest): Promise<ServerResponse> {
    return new Promise<ServerResponse>(resolve => {
      axios.post(`${ PUBLIC_API_BASE_URL }${ API_URL_PATH }${ method }`, instanceToPlain(request, { excludePrefixes: ['_'] })).then(response => {
        resolve(response.data)
      })
    })
  }

  private PUT(method: string, request: ServerRequest): Promise<ServerResponse> {
    return new Promise<ServerResponse>(resolve => {
      axios.put(`${ PUBLIC_API_BASE_URL }${ API_URL_PATH }${ method }`, instanceToPlain(request, { excludePrefixes: ['_'] })).then(response => {
        resolve(response.data)
      })
    })
  }

  private DELETE(method: string, confirm: boolean): Promise<ServerResponse> {
    return new Promise<ServerResponse>(resolve => {
      axios({
        url: `${ PUBLIC_API_BASE_URL }${ API_URL_PATH }${ method }`,
        method: 'DELETE',
        data: instanceToPlain(new ServerRequest(null, { confirm: confirm }), { excludePrefixes: ['_'] }),
      }).then(response => {
        resolve(response.data)
      })
    })
  }

  // API standard calls

  private all<T>(method: string, type: ClassConstructor<T>): Promise<T[]> {
    return new Promise<T[]>(resolve => {
      this.GET(`${ method }/all`).then((response: ServerResponse) => {
        resolve(plainToInstance(type, (response.data ?? []) as []))
      })
    })
  }

  private get<T>(method: string, type: ClassConstructor<T>, id: string): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.GET(`${ method }/${ id }`).then((response: ServerResponse) => {
        if (response.success)
          resolve(plainToInstance(type, response.data))
        else // error
          reject(response)
      })
    })
  }

  private async save<T>(method: string, type: ClassConstructor<T>, entity: Entity): Promise<T> {
    if (entity.id) {
      return this.put(`${ method }/${ entity.id }`, type, entity)
    } else {
      return this.post(method, type, entity)
    }
  }

  private async post<T>(method: string, type: ClassConstructor<T>, data: unknown): Promise<T> {
    let result: T
    let error: ServerResponse
    try {
      const response = await this.POST(method, new ServerRequest(data))
      if (response.success) {
        result = plainToInstance(type, response.data)
      } else {
        error = response
      }
    } catch (e) {
      error = e as ServerResponse
    }
    return new Promise<T>((resolve, reject) => {
      if (error) {
        reject(error)
      } else {
        resolve(result)
      }
    })
  }

  private async put<T>(method: string, type: ClassConstructor<T>, data: unknown): Promise<T> {
    let result: T
    let error: ServerResponse
    try {
      const response = await this.PUT(method, new ServerRequest(data))
      if (response.success) {
        result = plainToInstance(type, response.data)
      } else {
        error = response
      }
    } catch (e) {
      error = e as ServerResponse
    }
    return new Promise<T>((resolve, reject) => {
      if (error) {
        reject(error)
      } else {
        resolve(result)
      }
    })
  }

  private delete(method: string, id: string, confirm: boolean): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.DELETE(`${ method }/${ id }`, confirm).then((response: ServerResponse) => {
        if (response.success)
          resolve(true)
        else // error
          reject(response)
      })
    })
  }

  private async upload<T>(method: string, type: ClassConstructor<T>, file: File): Promise<T> {
    const formData = new FormData()
    formData.append('file', file)
    const headers = { 'Content-Type': 'multipart/form-data' }
    // upload file to server
    const response = (await axios.post(`${ PUBLIC_API_BASE_URL }${ API_URL_PATH }${ method }`, formData, { headers })).data
    // return the server response
    return new Promise<T>((resolve, reject) => {
      if (response.success) {
        resolve(plainToInstance(type, response.data))
      } else {
        reject(response)
      }
    })
  }

  // Helpers

  public resourceUrl(method: string): string {
    return `${ PUBLIC_API_BASE_URL }${ API_URL_PATH }resource/${ method }`
  }

  // SignUp methods

  public signup(name: string, email: string, phone: string, password: string, promo: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const request = new ServerRequest({ name: name, email: email, phone: phone, password: password, promo: promo })
      this.POST('signup/create', request).then((response: ServerResponse) => {
        if (response.success) {
          resolve(true)
        } else {
          reject(response)
        }
      })
    })
  }

  public async validatePromoCode(promo: string): Promise<Promo> {
    return new Promise<Promo>((resolve, reject) => {
      const request = new ServerRequest({ promo: promo })
      this.POST('signup/promo', request).then((response: ServerResponse) => {
        if (response.success) {
          resolve(plainToInstance(Promo, response.data))
        } else {
          reject(response)
        }
      })
    })
  }

  // Session methods

  public login(email: string, password: string): Promise<LoginResult> {
    return new Promise<LoginResult>(resolve => {
      const request = new ServerRequest({ email: email, password: password })
      this.POST('session/login', request).then((response: ServerResponse) => {
        const result = new LoginResult()
        // validate login
        if (response.success) {
          result.code = LoginResultCode.Success
          result.account = plainToInstance(BasicAccountData, response.data)
          // store the account data
          LocalStorage.Instance.me = result.account
        } else {
          switch (response.code) {
            case ServerErrorCodes.InvalidSessionAuth:
              result.code = LoginResultCode.InvalidAuth
              break
            case ServerErrorCodes.SessionForbiddenAccess:
              result.code = LoginResultCode.AccessForbidden
              break
            default:
              result.code = LoginResultCode.UnknownError
          }
          // clean-up stored account data
          LocalStorage.Instance.me = null
        }
        resolve(result)
      })
    })
  }

  public async rememberPassword(email: string): Promise<void> {
    const request = new ServerRequest({ email: email })
    await this.POST('session/login/forgot', request)
  }

  public logout(): Promise<void> {
    return new Promise<void>(resolve => {
      this.GET('session/logout').then(() => {
        LocalStorage.Instance.me = null
        resolve()
      })
    })
  }

  public meBasic(): Promise<BasicAccountData> {
    return new Promise<BasicAccountData>(resolve => {
      this.GET('session/me/basic').then((response: ServerResponse) => {
        resolve(plainToInstance(BasicAccountData, response.data))
      })
    })
  }

  public me(): Promise<AccountData> {
    return new Promise<AccountData>(resolve => {
      this.GET('session/me').then((response: ServerResponse) => {
        resolve(plainToInstance(AccountData, response.data))
      })
    })
  }

  public meQrPreview(): string {
    return PUBLIC_API_BASE_URL + API_URL_PATH + 'resource/me/qr/preview'
  }

  public async meQrDownload(): Promise<string> {
    const uri = await (await this.GET('resource/me/qr/download')).data as string
    return PUBLIC_API_BASE_URL + uri

    //const url = PUBLIC_API_BASE_URL + API_URL_PATH + 'resource/me/qr/download'
    //const response = await axios.head(url)
    //return response.request.responseURL
  }

  public basicSettings(): Promise<BasicSettings> {
    return new Promise<BasicSettings>(resolve => {
      this.GET('session/settings').then((response: ServerResponse) => {
        resolve(plainToInstance(BasicSettings, response.data))
      })
    })
  }

  // Dishes methods

  public allDishes(): Promise<Dish[]> {
    return this.all('dish', Dish)
  }

  public allFavoriteDishes(): Promise<Dish[]> {
    return new Promise<Dish[]>(resolve => {
      this.GET(`dish/all/favorites`).then((response: ServerResponse) => {
        resolve(plainToInstance(Dish, (response.data ?? []) as []))
      })
    })
  }

  public getDish(id: string): Promise<Dish> {
    return this.get('dish', Dish, id)
  }

  public async saveDish(dish: Dish, photo: File | string | null): Promise<Dish> {
    // save the dish
    const resp = await this.save('dish', Dish, dish)
    // a file is pending to be uploaded?
    if (photo instanceof File) {
      resp.photo = await Server.Instance.uploadDishPhoto(resp, photo)
    } else if (typeof photo === 'string') {
      resp.photo = await Server.Instance.setDishPhotoFromGallery(resp, photo)
    }
    return resp
  }

  public deleteDish(id: string, confirm: boolean): Promise<boolean> {
    return this.delete('dish', id, confirm)
  }

  public uploadDishPhoto(dish: Dish, file: File): Promise<Photo> {
    return this.upload(`upload/photo/dish/${ dish.id }`, Photo, file)
  }

  public async setDishPhotoFromGallery(dish: Dish, file: string): Promise<Photo> {
    return plainToInstance(Photo, (await this.PUT(`dish/${ dish.id }/photo-gallery`, new ServerRequest(file))).data)
  }

  public async deleteDishDishesGroup(dish: Dish, dishesGroup: DishesGroup): Promise<boolean> {
    if (dishesGroup.id) {
      return this.delete(`dish/${ dish.id }/group/`, dishesGroup.id, true)
    }
    return false
  }

  public async saveDishDishesGroup(dish: Dish, dishesGroup: DishesGroup): Promise<DishesGroup> {
    // dish not saved? save it first
    if (!dish.id) {
      dish = await this.saveDish(dish, null)
    }
    // save the group
    let group: DishesGroup = clone(dishesGroup)
    group.dishes = []
    group = await this.save(`dish/${ dish.id }/group`, DishesGroup, group)
    // prepare dishes to save
    const dishes: string[] = []
    dishesGroup.dishes?.forEach((dish: Dish) => {
      dishes.push(dish.id)
    })
    // save dishes
    const request = new ServerRequest(dishes, { replace: true })
    await this.POST(`dish/${ dish.id }/group/${ group.id }/dish`, request)
    group.dishes = dishesGroup.dishes
    // the
    return group
  }

  public async moveDishDishesGroup(dish: Dish, dishesGroup: DishesGroup, index: number): Promise<boolean> {
    if (dishesGroup.id) {
      const request = new ServerRequest(index)
      return (await this.PUT(`dish/${ dish.id }/group/${ dishesGroup.id }/move`, request)).success
    }
    return false
  }

  //  Categories

  public allCategories(): Promise<Category[]> {
    return this.all('category', Category)
  }

  public getCategory(id: string): Promise<Category> {
    return this.get('category', Category, id)
  }

  public saveCategory(category: Category): Promise<Category> {
    return this.save('category', Category, category)
  }

  public deleteCategory(id: string, confirm: boolean): Promise<boolean> {
    return this.delete('category', id, confirm)
  }

  // Allergens

  public allAllergens(): Promise<Allergen[]> {
    return this.all('allergen', Allergen)
  }

  public getAllergen(id: string): Promise<Allergen> {
    return this.get('allergen', Allergen, id)
  }

  public saveAllergen(allergen: Allergen): Promise<Allergen> {
    return this.save('allergen', Allergen, allergen)
  }

  public deleteAllergen(id: string, confirm: boolean): Promise<boolean> {
    return this.delete('allergen', id, confirm)
  }

  // Menus

  public allMenus(): Promise<Menu[]> {
    return this.all('menu', Menu)
  }

  public getMenu(id: string): Promise<Menu> {
    return this.get('menu', Menu, id)
  }

  public async saveMenu(menu: Menu, photo: File | null): Promise<Menu> {
    // save the dish
    const resp = await this.save('menu', Menu, menu)
    // a file is pending to be uploaded?
    if (photo) {
      resp.photo = await Server.Instance.uploadMenuPhoto(resp, photo)
    }
    return resp
  }

  public deleteMenu(id: string, confirm: boolean): Promise<boolean> {
    return this.delete('menu', id, confirm)
  }

  public async saveMenuDishesGroup(menu: Menu, dishesGroup: DishesGroup): Promise<DishesGroup> {
    // menu not saved? save it first
    if (!menu.id) {
      menu = await this.saveMenu(menu, null)
    }
    // save the group
    let group: DishesGroup = clone(dishesGroup)
    group.dishes = []
    group = await this.save(`menu/${ menu.id }/group`, DishesGroup, group)
    // prepare dishes to save
    const dishes: string[] = []
    dishesGroup.dishes?.forEach((dish: Dish) => {
      dishes.push(dish.id)
    })
    // save dishes
    const request = new ServerRequest(dishes, { replace: true })
    await this.POST(`menu/${ menu.id }/group/${ group.id }/dish`, request)
    group.dishes = dishesGroup.dishes
    // the 
    return group
  }

  public async deleteMenuDishesGroup(menu: Menu, dishesGroup: DishesGroup): Promise<boolean> {
    if (dishesGroup.id) {
      return this.delete(`menu/${ menu.id }/group/`, dishesGroup.id, true)
    }
    return false
  }

  public async moveMenuDishesGroup(menu: Menu, dishesGroup: DishesGroup, index: number): Promise<boolean> {
    if (dishesGroup.id) {
      const request = new ServerRequest(index)
      return (await this.PUT(`menu/${ menu.id }/group/${ dishesGroup.id }/move`, request)).success
    }
    return false
  }

  public uploadMenuPhoto(menu: Menu, file: File): Promise<Photo> {
    return this.upload(`upload/photo/menu/${ menu.id }`, Photo, file)
  }

  public previewMenu(menu: Menu): string {
    return PUBLIC_API_BASE_URL + API_URL_PATH + 'menu/' + menu.id + '/preview'
  }

  public async sortMenus(ids: string[]): Promise<void> {
    await this.PUT('menu/sort', new ServerRequest(ids))
  }

  // Fixed Menus

  public allFixedMenus(): Promise<FixedMenu[]> {
    return this.all('fixed-menu', FixedMenu)
  }

  public getFixedMenu(id: string): Promise<FixedMenu> {
    return this.get('fixed-menu', FixedMenu, id)
  }

  public async saveFixedMenu(menu: FixedMenu, photo: File | null): Promise<FixedMenu> {
    // save the dish
    const resp = await this.save('fixed-menu', FixedMenu, menu)
    // a file is pending to be uploaded?
    if (photo) {
      resp.photo = await Server.Instance.uploadFixedMenuPhoto(resp, photo)
    }
    return resp
  }

  public deleteFixedMenu(id: string, confirm: boolean): Promise<boolean> {
    return this.delete('fixed-menu', id, confirm)
  }

  public async saveFixedMenuDishesGroup(menu: FixedMenu, dishesGroup: DishesGroup): Promise<DishesGroup> {
    // menu not saved? save it first
    if (!menu.id) {
      menu = await this.saveFixedMenu(menu, null)
    }
    // save the group
    let group: DishesGroup = clone(dishesGroup)
    group.dishes = []
    group = await this.save(`fixed-menu/${ menu.id }/group`, DishesGroup, group)
    // prepare dishes to save
    const dishes: string[] = []
    dishesGroup.dishes?.forEach((dish: Dish) => {
      dishes.push(dish.id)
    })
    // save dishes
    const request = new ServerRequest(dishes, { replace: true })
    await this.POST(`fixed-menu/${ menu.id }/group/${ group.id }/dish`, request)
    group.dishes = dishesGroup.dishes
    // the 
    return group
  }

  public async deleteFixedMenuDishesGroup(menu: FixedMenu, dishesGroup: DishesGroup): Promise<boolean> {
    if (dishesGroup.id) {
      return this.delete(`fixed-menu/${ menu.id }/group/`, dishesGroup.id, true)
    }
    return false
  }

  public async moveFixedMenuDishesGroup(menu: FixedMenu, dishesGroup: DishesGroup, index: number): Promise<boolean> {
    if (dishesGroup.id) {
      const request = new ServerRequest(index)
      return (await this.PUT(`fixed-menu/${ menu.id }/group/${ dishesGroup.id }/move`, request)).success
    }
    return false
  }

  public uploadFixedMenuPhoto(menu: FixedMenu, file: File): Promise<Photo> {
    return this.upload(`upload/photo/fixed-menu/${ menu.id }`, Photo, file)
  }

  public previewFixedMenu(menu: FixedMenu): string {
    return PUBLIC_API_BASE_URL + API_URL_PATH + 'fixed-menu/' + menu.id + '/preview'
  }

  public async sortFixedMenus(ids: string[]): Promise<void> {
    await this.PUT('fixed-menu/sort', new ServerRequest(ids))
  }

  // Tables

  public allTables(): Promise<Tables[]> {
    return this.all('tables', Tables)
  }

  public getTables(id: string): Promise<Tables> {
    return this.get('tables', Tables, id)
  }

  public saveTables(tables: Tables): Promise<Tables> {
    return this.save('tables', Tables, tables)
  }

  public deleteTables(id: string, confirm: boolean): Promise<boolean> {
    return this.delete('tables', id, confirm)
  }

  public tablesQrPreview(id: string, all: boolean): string {
    return PUBLIC_API_BASE_URL + API_URL_PATH + 'tables/' + id + '/qr/' + (all ? 'all' : 'unified')
  }

  public async tablesQrDownload(id: string, all: boolean): Promise<string> {
    const uri = await (await this.GET('tables/' + id + '/qr/' + (all ? 'all' : 'unified') + '/external')).data as string
    return PUBLIC_API_BASE_URL + uri
  }

  // Orders

  public activeOrders(): Promise<Order[]> {
    return new Promise<Order[]>(resolve => {
      this.GET(`order/active`).then((response: ServerResponse) => {
        resolve(plainToInstance(Order, (response.data ?? []) as []))
      })
    })
  }

  public finishedOrders(options: OrdersFilterOptions): Promise<FinishedOrdersResult> {
    // get finished orders
    return new Promise<FinishedOrdersResult>(resolve => {
      this.POST(`order/finished`, new ServerRequest(null, options)).then((response: ServerResponse) => {
        resolve(plainToInstance(FinishedOrdersResult, response.data))
      })
    })
  }

  public async exportFinishedOrders(options: OrdersFilterOptions): Promise<string> {
    const uri = await (await this.POST(`order/finished/export/generate`, new ServerRequest(null, options))).data as string
    return PUBLIC_API_BASE_URL + uri
  }

  public initOrder(order: Order): Promise<Order | null> {
    const data = {
      tablesId: order.table.tablesId,
      tableNum: order.table.tableNum,
      people: order.table.people,
    }
    return this.post(`order/init`, Order, data)
  }

  public initBarOrder(): Promise<Order | null> {
    return this.post(`order/init/bar`, Order, {})
  }

  public confirmOrder(order: Order): Promise<Order> {
    return this.put(`order/${ order.id }/confirm`, Order, {})
  }

  public getOrder(id: string): Promise<Order> {
    return this.get('order', Order, id)
  }

  public addOrderLine(order: Order, line: OrderDishSelection | OrderFixedMenuSelection): Promise<OrderDishSelection | OrderFixedMenuSelection> {
    if (line instanceof OrderDishSelection) {
      return this.post(`order/${ order.id }/line`, OrderDishSelection, line)
    } else {
      return this.post(`order/${ order.id }/line`, OrderFixedMenuSelection, line)
    }
  }

  public editOrderLineDish(order: Order, lineId: string, info: OrderDishSelection): Promise<OrderDishSelection> {
    return this.put(`order/${ order.id }/line/${ lineId }`, OrderDishSelection, info)
  }

  public editOrderLineSelection<T>(order: Order, line: OrderSelection, quantity: number, notes: string | null, selectionId: string | null, dishId: string | null): Promise<T> {
    const data = {
      quantity: quantity,
      notes: notes,
      selectionId: selectionId,
      dishId: dishId,
    }
    // get the line class
    const cls: ClassConstructor<never> = Object.getPrototypeOf(line).constructor
    // edit an existing order line
    return new Promise<T>(resolve => {
      this.PUT(`order/${ order.id }/line/${ line.id }`, new ServerRequest(data)).then((response: ServerResponse) => {
        resolve(plainToInstance(cls, response.data))
      })
    })
  }

  public moveOrderSelectionToAnotherOrder(fromOrder: Order, selectionIds: string[], toOrder: Order): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.PUT(`order/${ fromOrder.id }/move-lines/${ toOrder.id }`, new ServerRequest(selectionIds)).then((response: ServerResponse) => {
        resolve(response.data as boolean)
      })
    })
  }

  public markOrderAsServed(order: Order): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.GET(`order/${ order.id }/served`).then((response: ServerResponse) => {
        resolve(response.data as boolean)
      })
    })
  }

  public markOrderDishSelectionAsServed(order: Order, line: OrderDishSelection): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.GET(`order/${ order.id }/served/${ line.id }/main`).then((response: ServerResponse) => {
        resolve(response.data as boolean)
      })
    })
  }

  public markOrderSelectionAsServed(order: Order, line: OrderSelection, selection: OrderSelectionDish): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.GET(`order/${ order.id }/served/${ line.id }/${ selection.id }`).then((response: ServerResponse) => {
        resolve(response.data as boolean)
      })
    })
  }

  // DEPRECATED
  public payOrder(order: Order, payment: string): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.PUT(`order/${ order.id }/pay`, new ServerRequest({ payment: payment })).then((response: ServerResponse) => {
        resolve(response.data as boolean)
      })
    })
  }

  // DEPRECATED
  public markOrderDishSelectionAsPaid(order: Order, line: OrderDishSelection, payment: string): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.PUT(`order/${ order.id }/pay/${ line.id }`, new ServerRequest({ payment: payment })).then((response: ServerResponse) => {
        resolve(response.data as boolean)
      })
    })
  }

  // DEPRECATED
  public markOrderDishSelectionAsUnpaid(order: Order, line: OrderDishSelection): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.GET(`order/${ order.id }/pay/${ line.id }/cancel`).then((response: ServerResponse) => {
        resolve(response.data as boolean)
      })
    })
  }

  public addOrderPayment(order: Order, payment: OrderPayment): Promise<OrderPayment> {
    return this.put(`order/${ order.id }/payment/add`, OrderPayment, payment)
  }

  public finishOrder(order: Order): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.GET(`order/${ order.id }/finish`).then((response: ServerResponse) => {
        resolve(response.data as boolean)
      })
    })
  }

  public cancelOrder(order: Order): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.GET(`order/${ order.id }/cancel`).then((response: ServerResponse) => {
        resolve(response.data as boolean)
      })
    })
  }

  public createChefCall(order: Order): Promise<ChefCall | null> {
    return new Promise<ChefCall | null>(resolve => {
      this.GET(`order/${ order.id }/call/chef/create`).then((response: ServerResponse) => {
        resolve(plainToInstance(ChefCall, response.data))
      })
    })
  }

  public attendingChefCall(order: Order): Promise<ChefCall | null> {
    return new Promise<ChefCall | null>(resolve => {
      this.GET(`order/${ order.id }/call/chef/ok`).then((response: ServerResponse) => {
        resolve(plainToInstance(ChefCall, response.data))
      })
    })
  }

  public attendingPaymentCall(order: Order): Promise<PayCall | null> {
    return new Promise<PayCall | null>(resolve => {
      this.GET(`order/${ order.id }/call/payment/ok`).then((response: ServerResponse) => {
        resolve(plainToInstance(PayCall, response.data))
      })
    })
  }

  public orderEditor(order: Order, external: boolean): Promise<string> {
    const url = 'order/' + order.id + '/editor'
    // return the url to use
    return new Promise<string>(resolve => {
      if (external) {
        this.GET(url + '/external').then((response: ServerResponse) => {
          resolve(response.data as string)
        })
      } else {
        resolve(PUBLIC_API_BASE_URL + API_URL_PATH + url)
      }
    })
  }

  public orderTicketUrl(order: Order, allLines: boolean): string {
    const all = allLines ? 'all-lines' : ''
    return `${ PUBLIC_API_BASE_URL }${ API_URL_PATH }order/${ order.id }/pdf/ticket/${ all }?nr=${ this.randomId(5) }`
  }

  public orderLinesNotServedUrl(order: Order): string {
    return `${ PUBLIC_API_BASE_URL }${ API_URL_PATH }order/${ order.id }/pdf/not-served?nr=${ this.randomId(5) }`
  }

  public async printOrderTicket(order: Order, allLines: boolean): Promise<boolean> {
    const all = allLines ? 'all-lines' : ''
    return (await this.GET(`order/${ order.id }/print/ticket/${ all }`)).data as boolean
  }

  public async printOrderLinesNotServed(order: Order): Promise<boolean> {
    return (await this.GET(`order/${ order.id }/print/not-served`)).data as boolean
  }

  // Profile (Me)

  public getMe(): Promise<AccountData> {
    return new Promise<AccountData>(resolve => {
      this.GET(`session/me`).then((response: ServerResponse) => {
        resolve(plainToInstance(AccountData, response.data))
      })
    })
  }

  public async saveMe(me: AccountData, logo: File | null): Promise<AccountData> {
    // save profile
    const profile = plainToInstance(AccountData, (await this.PUT(`session/me`, new ServerRequest(me))).data)
    // upload the logo (if a new logo is assigned)
    if (logo) {
      profile.logo = await this.upload(`upload/photo/profile`, Photo, logo)
    }
    // the profile
    return profile
  }

  public async changePassword(password: string): Promise<AccountData> {
    return this.put(`session/me`, AccountData, { password: password })
  }

  // Subscription

  public getSubscriptionDetails(): Promise<SubscriptionDetails> {
    return new Promise<SubscriptionDetails>(resolve => {
      this.GET(`subscription/details`).then((response: ServerResponse) => {
        resolve(plainToInstance(SubscriptionDetails, response.data))
      })
    })
  }

  public getSubscriptionPrices(): Promise<SubscriptionPlanItem[]> {
    return new Promise<SubscriptionPlanItem[]>(resolve => {
      this.GET(`subscription/prices`).then((response: ServerResponse) => {
        resolve(plainToInstance(SubscriptionPlanItem, (response.data ?? []) as []))
      })
    })
  }

  public generateCheckoutSessionToken(plan: string, external: boolean): Promise<string> {
    return new Promise<string>(resolve => {
      const tail = external ? '/external' : ''
      this.GET(`subscription/stripe/create-checkout-session/${ plan }${ tail }`).then((response: ServerResponse) => {
        resolve(response.data as string)
      })
    })
  }

  public getSubscriptionCustomerPortalUrl(): Promise<string> {
    return new Promise<string>(resolve => {
      this.GET(`subscription/stripe/customer-poral-url`).then((response: ServerResponse) => {
        resolve(response.data as string)
      })
    })
  }

  public subscriptionRedirectToCheckout(sessionId: string): string {
    return `${ PUBLIC_API_BASE_URL }${ API_URL_PATH }subscription/stripe/checkout-payment-url/${ sessionId }`
  }

  // Payments

  public getPaymentMethodsDetails(): Promise<PaymentMethods> {
    return new Promise<PaymentMethods>(resolve => {
      this.GET(`payments/settings/methods`).then((response: ServerResponse) => {
        resolve(plainToInstance(PaymentMethods, response.data))
      })
    })
  }

  public savePaymentMethodsDetails(paymentMethods: PaymentMethods): Promise<PaymentMethods> {
    return new Promise<PaymentMethods>(resolve => {
      this.PUT(`payments/settings/methods`, new ServerRequest(paymentMethods)).then((response: ServerResponse) => {
        resolve(plainToInstance(PaymentMethods, response.data))
      })
    })
  }

  public getOnlineOnlinePayments(): Promise<OnlinePayments> {
    return new Promise<OnlinePayments>(resolve => {
      this.GET(`payments/settings/online`).then((response: ServerResponse) => {
        resolve(plainToInstance(OnlinePayments, response.data))
      })
    })
  }

  public saveOnlineOnlinePayments(onlinePayments: OnlinePayments): Promise<OnlinePayments> {
    return new Promise<OnlinePayments>(resolve => {
      this.PUT(`payments/settings/online`, new ServerRequest(onlinePayments)).then((response: ServerResponse) => {
        resolve(plainToInstance(OnlinePayments, response.data))
      })
    })
  }

  public getPaymentsCustomerAccountUrl(): Promise<string> {
    return new Promise<string>(resolve => {
      this.GET(`payments/settings/online/stripe/account/url`).then((response: ServerResponse) => {
        resolve(response.data as string)
      })
    })
  }

  public disconnectOnlinePaymentAccount(): Promise<OnlinePayments> {
    return new Promise<OnlinePayments>((resolve, reject) => {
      this.GET(`payments/settings/online/stripe/account/disconnect`).then((response: ServerResponse) => {
        if (response.success)
          resolve(plainToInstance(OnlinePayments, response.data))
        else // error
          reject(response)
      })
    })
  }

  // Sales Channels

  public getSalesChannels(): Promise<SalesChannels> {
    return new Promise<SalesChannels>(resolve => {
      this.GET(`sales-channels/details`).then((response: ServerResponse) => {
        resolve(plainToInstance(SalesChannels, response.data))
      })
    })
  }

  public saveSalesChannels(salesChannels: SalesChannels): Promise<SalesChannels> {
    return new Promise<SalesChannels>(resolve => {
      this.PUT(`sales-channels/details`, new ServerRequest(salesChannels)).then((response: ServerResponse) => {
        resolve(plainToInstance(SalesChannels, response.data))
      })
    })
  }

  // Printer Settings

  public getPrinterSettings(): Promise<PrinterSettings> {
    return new Promise<PrinterSettings>(resolve => {
      this.GET(`printer-settings/details`).then((response: ServerResponse) => {
        resolve(plainToInstance(PrinterSettings, response.data))
      })
    })
  }

  public savePrinterSettings(printerSettings: PrinterSettings): Promise<PrinterSettings> {
    return new Promise<PrinterSettings>(resolve => {
      this.PUT(`printer-settings/details`, new ServerRequest(printerSettings)).then((response: ServerResponse) => {
        resolve(plainToInstance(PrinterSettings, response.data))
      })
    })
  }

  // Live Options

  public getLiveOptions(): Promise<LiveOptions> {
    return new Promise<LiveOptions>(resolve => {
      this.GET(`live-options`).then((response: ServerResponse) => {
        resolve(plainToInstance(LiveOptions, response.data))
      })
    })
  }

  public saveLiveOptions(liveOptions: LiveOptions): Promise<LiveOptions> {
    return new Promise<LiveOptions>(resolve => {
      this.PUT(`live-options`, new ServerRequest(liveOptions)).then((response: ServerResponse) => {
        resolve(plainToInstance(LiveOptions, response.data))
      })
    })
  }

  // Galleries

  public getDishesGallery(): Promise<Photo[]> {
    return new Promise<Photo[]>(resolve => {
      this.GET(`galleries/common/dishes`).then((response: ServerResponse) => {
        resolve(plainToInstance(Photo, (response.data ?? []) as []))
      })
    })
  }

  public dishGalleryUrl(id: string): string {
    return `${ PUBLIC_API_BASE_URL }${ API_URL_PATH }resource/galleries/common/dishes/${ id }/512x512`
  }

  // Notifications

  public registerDevice(token: string): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.POST(`notifications/register`, new ServerRequest(token)).then((response: ServerResponse) => {
        resolve(response.data as boolean)
      })
    })
  }
}