import { Entity } from "./Entity"
import { Tables } from "./Tables"
import { Restaurant } from "./Restaurant"

export class Table extends Entity {
    public tablesId!: string
    public tableNum!: number
    public people!: number
    public title!: string

    private _tables!: Tables

    public get tables(): Tables {
        if (!this._tables) {
            this._tables = Restaurant.Instance.getTables(this.tablesId) as Tables
        }
        return this._tables
    }
}