import { Entity } from '@/models/Entities/Entity'
import { Server } from '../Server'

export const ONLINE_TABLE_ID = 'online'

export class Tables extends Entity {
    public active!: boolean
    public title!: string
    public description!: string
    public count!: number
    public extraFee!: number
    public remoteDisabled = false

    constructor() {
        super()
        this.active = false
    }

    public qr(all: boolean): string {
        return Server.Instance.tablesQrPreview(this.id, all)
    }
}