import ObserverBase from 'fast-observer'

export enum ObserverEvents {
  LoginRequired = 'login-required',
  LogoutRequested = 'logout-requested',
  ServerConnectionError = 'server-connection-error',
  FullscreenEnabled = 'fullscreen-enabled',
  FullscreenDisabled = 'fullscreen-disabled',
  ModalDialogActivated = 'modal-dialog-activated',
  ModalDialogDesactivated = 'modal-dialog-desactivated',
  ToggleExpandMenu = 'toggle-expand-menu',
  ToggleDarknight = 'toggle-darknight',
  MenuExpandStateChanged = 'menu-expand-state-changed',
  MenuSectionChanged = 'menu-section-changed',
  ProfileChanged = 'profile-changed',
  OrderChanged = 'order-changed',
  OrderLinesChanged = 'order-lines-changed',
  ExpandAllCardComponents = 'expand-all-card-components',
  CollapseAllCardComponents = 'collapse-all-card-components',
  BeforeRouteLeave = 'before-route-leave',
  AppUpdateFound = 'app-update-found',
  AppUpdateFinished = 'app-update-finished',
}

export class Observer extends ObserverBase {
  private static _instance: Observer

  public static get Instance(): Observer {
    return this._instance || (this._instance = new this())
  }
}