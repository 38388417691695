import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6893ae38"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "aside-tools" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_aside_menu_list = _resolveComponent("aside-menu-list")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("aside", {
      id: "aside-main",
      class: _normalizeClass(["aside is-placed-left", { 'is-expanded': _ctx.isAsideMenuExpanded }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.isAsideMenuExpanded)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _ctx.logoSmall(),
              class: "pl-0 pt-4 pb-4"
            }, null, 8, _hoisted_2))
          : (_openBlock(), _createElementBlock("img", {
              key: 1,
              src: _ctx.logoShort(),
              class: "p-3 pt-4 small-logo"
            }, null, 8, _hoisted_3))
      ]),
      _createVNode(_component_aside_menu_list, {
        items: _ctx.menu1,
        title: "Platos y menus"
      }, null, 8, ["items"]),
      _createVNode(_component_aside_menu_list, {
        items: _ctx.menu2,
        title: "Mesas"
      }, null, 8, ["items"]),
      _createVNode(_component_aside_menu_list, {
        items: _ctx.menu3,
        title: "Pedidos"
      }, null, 8, ["items"])
    ], 2),
    (_ctx.isAsideMenuExpanded)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "overlay animate__animated animate__fadeIn",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleExpandMenu()))
        }))
      : _createCommentVNode("", true)
  ], 64))
}