<template>
  <div :class="{ background: !isEmbeded }">
    <section
      :class="{ 'section hero is-fullheight is-error-section': !isEmbeded }"
    >
      <div :class="{ 'hero-body': !isEmbeded }">
        <div class="has-text-centered" :class="{ container: !isEmbeded }">
          <p
            class="
              is-size-3
              has-text-weight-bold
              animate__animated animate__fadeInDown animate__fast
            "
            :class="{ 'is-size-4': isSmall }"
          >
            {{ title }}
          </p>
          <i
            class="
              fal
              fa-lock-alt
              mt-3
              mb-5
              animate__animated animate__tada animate__fast
            "
            :class="{ 'fa-4x': isSmall, 'fa-5x': !isSmall }"
          ></i>
          <p
            class="
              is-size-5
              description
              animate__animated animate__fadeInUp animate__fast
            "
            :class="{ 'is-size-6': isSmall }"
          >
            {{ description }}
          </p>
          <button
            class="
              button
              is-black
              mt-6
              animate__animated animate__fadeInUpSmall animate__delay-500ms
            "
            @click="upgrade()"
          >
            Actualizar mi plan
          </button>
          <button
            v-if="laterButton"
            class="
              button
              mt-6
              ml-3
              animate__animated animate__fadeInUpSmall animate__delay-500ms
            "
            @click="$emit('option-selected', 2)"
          >
            Más tarde
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    title: { type: String },
    description: { type: String },
    laterButton: { type: Boolean, default: false },
    isEmbeded: { type: Boolean, default: false },
    isSmall: { type: Boolean, default: false },
  },
  emits: [
    'option-selected',
  ]
})
export default class UpgradePlan extends Vue {
  private upgrade(): void {
    this.$router.push({ name: 'Settings', query: { section: 'subscription' } })
    this.$emit('option-selected', 1)
  }
}
</script>

<style lang="scss" scoped>
.background {
  background-color: rgba(255, 255, 255, 0.75);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.description {
  max-width: 700px;
  margin: 0 auto;
}

html {
  &.is-dark-mode-active {
    .background {
      background-color: rgba($color: #242424, $alpha: 0.75);
    }
  }
}

</style>