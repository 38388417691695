import { Type } from 'class-transformer'
import { BasicAccountData } from './BasicAccountData'

export enum LoginResultCode {
  None,
  Success,
  InvalidAuth,
  AccessForbidden,
  UnknownError
}

export class LoginResult {
  @Type(() => BasicAccountData) public account!: BasicAccountData
  public code!: LoginResultCode
  public message!: string
}