import { Dish } from '@/models/Entities/Dish'
import { OrderSelection, OrderSelectionDish } from '@/models/Entities/OrderSelection'
import { Restaurant } from '@/models/Entities/Restaurant'
import { plainToInstance, Type } from 'class-transformer'

export const OrderDishSelectionType = 'dish-line'

export class OrderDishComplementsSelection extends OrderSelectionDish {

  public clone(): OrderDishComplementsSelection {
    return plainToInstance(OrderDishComplementsSelection, this)
  }
}

export class OrderDishSelection extends OrderSelection {
  @Type(() => String) public type = OrderDishSelectionType
  @Type(() => String) public dishId: string
  @Type(() => String) public notes: string | null
  @Type(() => OrderDishComplementsSelection) public selection: OrderDishComplementsSelection[]

  private _dish!: Dish

  constructor(dishId: string, quantity: number, notes: string | null, selection: OrderDishComplementsSelection[]) {
    super()
    this.dishId = dishId
    this.quantity = quantity
    this.notes = notes
    this.selection = selection
  }

  public get partialServed(): boolean {
    if (this.selection && this.selection.length > 0) {
      return super.partialServed
    }
    return this.served
  }

  public get dish(): Dish {
    if (!this._dish) {
      this._dish = Restaurant.Instance.getDish(this.dishId) as Dish
    }
    return this._dish
  }

  public equals(other: OrderDishSelection, ignoreQuantity: boolean): boolean {
    if (other.dishId === this.dishId && other.notes === this.notes) {
      return ignoreQuantity ? true : other.quantity === this.quantity
    }
    return false
  }

  public canBeOrdered(): boolean {
    if (this.dish) {
      if (this.dish.hasComplements()) {
        for (let index = 0; index < this.dish.dishesGroups.length; index++) {
          if (this.dish.dishesGroups[index].isOptional()) continue
          if (!this.selectionByGroup(this.dish.dishesGroups[index])) return false
        }
      }
      return true
    }
    return false
  }

  public clone(): OrderDishSelection {
    return plainToInstance(OrderDishSelection, this)
  }
}