import DateTransform from '@/models/DateTransform'
import { OrderPayment } from '@/models/Entities/OrderPayment'
import { plainToClass, Type } from 'class-transformer'
import { Observer, ObserverEvents } from '../Observer'
import { Server } from '../Server'
import { ChefCall } from './ChefCall'
import { DeliveryInfo } from './DeliveryInfo'
import { Entity } from './Entity'
import { HeyCall } from './HeyCall'
import { OrderDishSelection, OrderDishSelectionType } from './OrderDishSelection'
import { OrderFixedMenuDishSelection, OrderFixedMenuSelection, OrderFixedMenuSelectionType } from './OrderFixedMenuSelection'
import { OrderPaymentType } from './OrderPaymentType'
import { OrderSelection, OrderSelectionDish } from './OrderSelection'
import { PayCall } from './PayCall'
import { Price } from './Price'
import { Table } from './Table'

export class Order extends Entity {
  @Type(() => String) public type = ''
  @Type(() => Table) public table: Table = new Table()
  @Type(() => String) public hash: string | null = null
  @Type(() => String) public code: string | null = null
  @Type(() => String) public number: string | null = null
  @Type(() => String) public invoice: string | null = null
  @Type(() => HeyCall) public heyCall: HeyCall | null = null
  @Type(() => PayCall) public payCall: PayCall | null = null
  @Type(() => PayCall) public chefCall: ChefCall | null = null
  @Type(() => DeliveryInfo) public delivery: DeliveryInfo | null = null
  @Type(() => Price) public deliveryFee: Price | null = null
  @Type(() => Price) public tip: Price | null = null
  @Type(() => OrderPayment) public payments: OrderPayment[] = []
  @Type(() => Boolean) public payingLines: boolean | null = null
  @DateTransform() public date!: Date
  @DateTransform() public updateDate!: Date

  private _lines: (OrderDishSelection | OrderFixedMenuSelection)[] = []

  public get lines(): (OrderDishSelection | OrderFixedMenuSelection)[] {
    return this._lines
  }

  public set lines(lines: (OrderDishSelection | OrderFixedMenuSelection)[]) {
    this._lines = []
    // assign lines
    for (let index = 0; index < lines.length; index++) {
      const aLine = lines[index]
      if (aLine instanceof OrderDishSelection || aLine instanceof OrderFixedMenuSelection) {
        this._lines.push(aLine)
      } else {
        if ((aLine as Record<string, unknown>).type === OrderDishSelectionType) {
          this._lines.push(plainToClass(OrderDishSelection, aLine as OrderDishSelection))
        } else if ((aLine as Record<string, unknown>).type === OrderFixedMenuSelectionType) {
          this._lines.push(plainToClass(OrderFixedMenuSelection, aLine as OrderFixedMenuSelection))
        }
      }
    }
  }

  public replaceOrAddLine(line: OrderDishSelection | OrderFixedMenuSelection): void {
    for (let index = 0; index < this.lines.length; index++) {
      if (this.lines[index].id === line.id) {
        if (line.quantity > 0) {
          this.lines[index] = line
        } else {
          this.lines.splice(index, 1)
        }
        this.linesChanged()
        return
      }
    }
    // is a new line
    this.lines.push(line)
    this.linesChanged()
  }

  public totalPrice(): Price {
    const price = new Price()
    // calcule the total amount
    for (let index = 0; index < this.lines.length; index++) {
      if (this.lines[index].confirmed) {
        price.value += this.lines[index].totalPrice().value
      }
    }
    // the price
    return price
  }

  public totalPaidPrice(): Price {
    const price = new Price()
    // calcule the total amount
    if (this.payments) {
      for (let index = 0; index < this.payments.length; index++) {
        if (this.payments[index].amount) {
          price.value += this.payments[index].amount.value
        }
      }
    }
    return price
  }

  public totalUnconfirmedPrice(): Price {
    const price = new Price()
    // calcule the total amount
    for (let index = 0; index < this.lines.length; index++) {
      if (!this.lines[index].confirmed) {
        price.value += this.lines[index].totalPrice().value
      }
    }
    // the price
    return price
  }

  public totalUnpaidPrice(): Price {
    const price = new Price(this.totalPrice())
    price.value -= this.totalPaidPrice().value
    return price
  }

  public hasUnpaidLines(includeNotConfirmed: boolean): boolean {
    for (let index = 0; index < this.lines.length; index++) {
      if (!this.lines[index].paid && (includeNotConfirmed || this.lines[index].confirmed)) {
        return true
      }
    }
    return false
  }

  public unpaidLines(includeNotConfirmed: boolean): OrderSelection[] {
    const lines: OrderSelection[] = []
    for (let index = 0; index < this.lines.length; index++) {
      if (!this.lines[index].paid && (includeNotConfirmed || this.lines[index].confirmed)) {
        lines.push(this.lines[index])
      }
    }
    return lines
  }

  private linesChanged(): void {
    Observer.Instance.publish(ObserverEvents.OrderLinesChanged, this)
  }

  public isEmpty(): boolean {
    return !this.isPreparing() && !this.isWaiting() && !this.isServed()
  }

  public isPreparing(): boolean {
    for (let index = 0; index < this.lines.length; index++) {
      if (this.lines[index].confirmed) {
        return false
      }
    }
    return true
  }

  public isWaiting(): boolean {
    return !this.isServed() && !this.isPreparing()
  }

  public isServed(): boolean {
    for (let index = 0; index < this.lines.length; index++) {
      if (this.lines[index].confirmed && !this.lines[index].allServed) {
        return false
      }
    }
    return true
  }

  public hasLinesConfirmed(): boolean {
    for (let index = 0; index < this.lines.length; index++) {
      if (this.lines[index].confirmed) {
        return true
      }
    }
    return false
  }

  public notConfirmedLines(excludePaidLines: boolean): (OrderDishSelection | OrderFixedMenuSelection)[] {
    const lines: (OrderDishSelection | OrderFixedMenuSelection)[] = []
    for (let index = 0; index < this.lines.length; index++) {
      if (!this.lines[index].confirmed) {
        if (excludePaidLines && this.lines[index].paid) continue
        lines.push(this.lines[index])
      }
    }
    return lines
  }

  public notServedLines(excludePaidLines: boolean): (OrderDishSelection | OrderFixedMenuSelection)[] {
    const lines: (OrderDishSelection | OrderFixedMenuSelection)[] = []
    for (let index = 0; index < this.lines.length; index++) {
      if (this.lines[index].confirmed && !this.lines[index].allServed) {
        if (excludePaidLines && this.lines[index].paid) continue
        lines.push(this.lines[index])
      }
    }
    return lines
  }

  public hasNotServedLines(): boolean {
    for (let index = 0; index < this.lines.length; index++) {
      if (this.lines[index].confirmed && !this.lines[index].allServed) {
        return true
      }
    }
    return false
  }

  public servedLines(excludePaidLines: boolean): (OrderDishSelection | OrderFixedMenuSelection)[] {
    const lines: (OrderDishSelection | OrderFixedMenuSelection)[] = []
    for (let index = 0; index < this.lines.length; index++) {
      if (this.lines[index].confirmed && (this.lines[index].served || this.lines[index].partialServed)) {
        if (excludePaidLines && this.lines[index].paid) continue
        lines.push(this.lines[index])
      }
    }
    return lines
  }

  public productsServedCount(excludePaidLines: boolean): number {
    let count = 0
    this.servedLines(false).forEach((line: OrderSelection) => {
      if (!excludePaidLines || excludePaidLines && !line.paid) {
        // count the selection
        if (line.selection) {
          line.selection.forEach((selection: OrderFixedMenuDishSelection) => {
            if (selection.served) count += line.quantity
          })
        }
        // add the dish quantity
        if (line instanceof OrderDishSelection && line.served) {
          count += line.quantity
        }
      }
    })
    return count
  }

  public productsNotServedCount(excludePaidLines: boolean): number {
    let count = 0
    this.notServedLines(false).forEach((line: OrderSelection) => {
      if (!excludePaidLines || excludePaidLines && !line.paid) {
        // count the selection
        if (line.selection) {
          line.selection.forEach((selection: OrderFixedMenuDishSelection) => {
            if (!selection.served) count += line.quantity
          })
        }
        // add the dish quantity
        if (line instanceof OrderDishSelection && !line.served) {
          count += line.quantity
        }
      }
    })
    return count
  }

  public productsNotConfirmedCount(excludePaidLines: boolean): number {
    let count = 0
    this.notConfirmedLines(false).forEach((line: OrderSelection) => {
      if (!excludePaidLines || excludePaidLines && !line.paid) {
        if (line.selection) {
          count += line.quantity * line.selection.length
        }
        if (line instanceof OrderDishSelection) {
          count += line.quantity
        }
      }
    })
    return count
  }

  public async markAllAsServed(): Promise<void> {
    await Server.Instance.markOrderAsServed(this)
    // inform about this
    this.linesChanged()
  }

  public async markDishSelectionAsServed(line: OrderDishSelection): Promise<void> {
    line.served = await Server.Instance.markOrderDishSelectionAsServed(this, line)
    // inform about this
    this.linesChanged()
  }

  // DEPRECATED
  public async markDishSelectionAsPaid(line: OrderDishSelection, payment: string): Promise<void> {
    if (await Server.Instance.markOrderDishSelectionAsPaid(this, line, payment)) {
      line.payment = payment
      // inform about this
      this.linesChanged()
    }
  }

  // DEPRECATED
  public async markDishSelectionAsUnpaid(line: OrderDishSelection): Promise<void> {
    if (line.payment !== OrderPaymentType.Online && await Server.Instance.markOrderDishSelectionAsUnpaid(this, line)) {
      line.payment = null
      // inform about this
      this.linesChanged()
    }
  }

  public async markSelectionAsServed(line: OrderSelection, selection: OrderSelectionDish): Promise<void> {
    selection.served = await Server.Instance.markOrderSelectionAsServed(this, line, selection)
    // inform about this
    this.linesChanged()
  }

  public async finish(): Promise<void> {
    await Server.Instance.finishOrder(this)
    // inform about this
    this.linesChanged()
  }

  public async cancel(): Promise<void> {
    await Server.Instance.cancelOrder(this)
    // inform about this
    this.linesChanged()
  }

  public async addPayment(type: string, amount: number | null, linesIds: string[] | null): Promise<void> {
    const payment = new OrderPayment()
    payment.type = type
    if (amount) {
      payment.amount = Price.Create(amount)
    }
    if (linesIds) {
      payment.linesIds = linesIds
    }
    // add payment
    this.payments.push(await Server.Instance.addOrderPayment(this, payment))
    // inform about this
    this.linesChanged()
  }

  public async allPaid(type: string): Promise<void> {
    let amount: number | null = null
    const lineIds = []
    if (this.payingLines === false) {
      amount = this.totalUnpaidPrice().value
    } else {
      const lines = this.unpaidLines(false)
      if (lines) {
        for (let index = 0; index < lines.length; index++) {
          lineIds.push(lines[index].id)
        }
      }
    }
    // add payment
    return this.addPayment(type, amount, lineIds)
  }

  public hasChefCall(): boolean {
    return this.chefCall !== null && !this.chefCall.ok
  }

  public hasPayCall(): boolean {
    return this.payCall !== null && !this.payCall.ok
  }

  public hasInvoice(): boolean {
    return this.invoice !== null
  }

  public isInSitu(): boolean {
    return this.type === 'in_situ'
  }

  public isBar(): boolean {
    return this.type === 'bar'
  }

  public isOnline(): boolean {
    return this.type === 'online' // table.tablesId === undefined
  }

  public isDelivery(): boolean {
    return this.isOnline() && this.delivery !== null && this.delivery !== undefined
  }

  public isTakeAway(): boolean {
    return this.isOnline() && !this.isDelivery()
  }

  public hasOnlinePayment(): boolean {
    for (let index = 0; index < this.payments.length; index++) {
      if (this.payments[index].type === OrderPaymentType.Online) return true
    }
    return false
  }

  public hasCreditCardPayment(): boolean {
    for (let index = 0; index < this.payments.length; index++) {
      if (this.payments[index].type === OrderPaymentType.CreditCard) return true
    }
    return false
  }

  public hasCashPayment(): boolean {
    for (let index = 0; index < this.payments.length; index++) {
      if (this.payments[index].type === OrderPaymentType.Cash) return true
    }
    return false
  }
}
