<template>
  <aside
    id="aside-main"
    class="aside is-placed-left"
    :class="{ 'is-expanded': isAsideMenuExpanded }"
  >
    <div class="aside-tools">
      <img
        v-if="isAsideMenuExpanded"
        :src="logoSmall()"
        class="pl-0 pt-4 pb-4"
      />
      <img v-else :src="logoShort()" class="p-3 pt-4 small-logo" />
    </div>
    <aside-menu-list :items="menu1" title="Platos y menus" />
    <aside-menu-list :items="menu2" title="Mesas" />
    <aside-menu-list :items="menu3" title="Pedidos" />
  </aside>
  <div
    v-if="isAsideMenuExpanded"
    class="overlay animate__animated animate__fadeIn"
    @click="toggleExpandMenu()"
  ></div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { MenuSections } from "@/models/MenuSections";
import { LocalStorage } from "@/models/LocalStorage";
import { Observer, ObserverEvents } from "@/models/Observer";
import { Watch } from 'vue-property-decorator';
import AsideMenuList from "@/components/AsideMenuList.vue";

@Options({
  components: {
    AsideMenuList,
  },
})
export default class AsideMenu extends Vue {
  private isAsideMenuExpanded = false
  private isDarkMode = false

  @Watch('$route', { immediate: true, deep: false }) onUrlChange(): void {
    if (this.isAsideMenuExpanded && document.body.clientWidth < 1024) {
      Observer.Instance.publish(ObserverEvents.ToggleExpandMenu)
    }
  }

  private menu1 = [
    { title: 'Platos y Bebidas', icon: 'fal fa-soup', section: MenuSections.Dishes },
    { title: 'Cartas', icon: 'fal fa-book-reader', section: MenuSections.Menus },
    { title: 'Menús', icon: 'fal fa-utensils', section: MenuSections.FixedMenus },
    { title: 'Categorías', icon: 'fal fa-layer-group', section: MenuSections.Categories },
    { title: 'Alérgenos', icon: 'fal fa-frown', section: MenuSections.Allergens },
  ]
  private menu2 = [
    { title: 'Gestionar mesas', icon: 'fal fa-chair', section: MenuSections.Tables },
  ]
  private menu3 = [
    { title: 'Activos', icon: 'fal fa-receipt', section: MenuSections.ActiveOrders },
    { title: 'Historial', icon: 'fal fa-file-chart-pie', section: MenuSections.FinishedOrders },
  ]

  public mounted(): void {
    // initial value
    this.isAsideMenuExpanded = LocalStorage.Instance.asideMenuExpanded
    this.isDarkMode = LocalStorage.Instance.darkMode
    // subscribe for menu expanded state changes
    Observer.Instance.subscribe(ObserverEvents.MenuExpandStateChanged, (expanded: boolean) => {
      this.isAsideMenuExpanded = expanded
    })
    // subscribe for darkmode state changes
    Observer.Instance.subscribe(ObserverEvents.ToggleDarknight, () => {
      this.isDarkMode = LocalStorage.Instance.darkMode
      this.$forceUpdate()
    })
  }

  private toggleExpandMenu(): void {
    Observer.Instance.publish(ObserverEvents.ToggleExpandMenu)
  }

  private logoSmall(): string {
    return require('@/assets/images/common/logo-small' + (this.isDarkMode ? '-dark-mode' : '') + '.svg');
  }

  private logoShort(): string {
    return require('@/assets/images/common/logo-short' + (this.isDarkMode ? '-dark-mode' : '') + '.svg');
  }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1023px) {
  .overlay {
    background-color: rgba(255, 255, 255, 0.9);
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}

.aside {
  overflow-y: auto;
}

.aside-tools {
  margin-top: env(safe-area-inset-top);
  padding-left: env(safe-area-inset-left);
}

.small-logo {
  max-width: 72px;
}

html {
  &.is-dark-mode-active {
    .overlay {
      background-color: rgba(36, 36, 36, 0.9);
    }
  }
}
</style>