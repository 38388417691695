<template>
  <div v-if="isLoginRequired">
    <login-form />
  </div>
  <div v-else-if="isConnectionError">
    <error-dialog
        title="Error de conexión"
        icon="fal fa-wifi-slash"
    >
      <template v-slot:description>
        <p>
          No se ha podido establecer una conexión con el servidor, por favor
          comprueba tu conexión a Internet y vuelve a probarlo.
        </p>
      </template>
      <template v-slot:buttons>
        <button
            type="button"
            class="button is-black"
            @click="reconnect()"
        >
          Volver a probar
        </button>
      </template>
    </error-dialog>
  </div>
  <div v-else>
    <template v-if="!isFullScreenEnabled">
      <nav-bar ref="navBar" />
      <aside-menu />
    </template>
    <router-view />
  </div>
  <modal-confirmation />
  <upgrade-plan-dialog />
  <div
      v-if="updating"
      class="update-view"
  >
    <div class="is-loading"></div>
  </div>
</template>

<script lang="ts">

import AsideMenu from '@/components/AsideMenu.vue'
import ErrorDialog from '@/components/ErrorDialog.vue'
import LoginForm from '@/components/LoginForm.vue'
import ModalConfirmation from '@/components/ModalConfirmation.vue'
import NavBar from '@/components/NavBar.vue'
import UpgradePlanDialog from '@/components/UpgradePlanDialog.vue'
import { Observer, ObserverEvents } from '@/models/Observer'
import { PushActions } from '@/models/PushActions'
import { Server } from '@/models/Server'
import { FCM } from '@capacitor-community/fcm'
import { Capacitor } from '@capacitor/core'
import { ActionPerformed as LocalActionPerformed, LocalNotifications } from '@capacitor/local-notifications'
import { ActionPerformed, PushNotifications, PushNotificationSchema } from '@capacitor/push-notifications'
import { Options, Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { BasicAccountData } from './models/BasicAccountData'
import { LocalStorage } from './models/LocalStorage'

const DEFAULT_PUSH_CHANNEL = 'default-channel-panel'

@Options({
  components: {
    NavBar,
    AsideMenu,
    LoginForm,
    ErrorDialog,
    ModalConfirmation,
    UpgradePlanDialog,
  },
})
export default class App extends Vue {

  private isLoginRequired = false
  private isConnectionError = false
  private isFullScreenEnabled = true
  private activeModalsCount = 0
  private previousWindowScrollY = 0
  private updating = false

  @Watch('$route', { immediate: true, deep: true }) beforeRouteUpdate(to: Record<string, unknown>): void {
    if (to.name === 'SignUp' || to.name === 'Login') {
      this.isLoginRequired = false
    }
  }

  public beforeCreate(): void {
    // small hack to prevent "login required" when is a "landing special page"
    if (!window.location.href.includes('/landing/')) {
      Server.Instance.meBasic().then((result: BasicAccountData) => {
        LocalStorage.Instance.me = result
      })
    }

    Observer.Instance.subscribe(ObserverEvents.AppUpdateFound, () => {
      this.updating = true
    })

    Observer.Instance.subscribe(ObserverEvents.AppUpdateFinished, () => {
      this.updating = false
    })

    Observer.Instance.subscribe(ObserverEvents.LoginRequired, () => {
      this.isLoginRequired = true
    })

    Observer.Instance.subscribe(ObserverEvents.LogoutRequested, () => {
      Server.Instance.logout().then(() => {
        this.$router.replace({ name: 'Login' })
      })
    })

    Observer.Instance.subscribe(ObserverEvents.FullscreenEnabled, () => {
      this.$store.state.isNavBarVisible = false
      this.$store.state.isAsideVisible = false
      this.isFullScreenEnabled = true
      // update css
      document.documentElement.classList.remove('has-aside-left', 'has-navbar-fixed-top')
    })

    Observer.Instance.subscribe(ObserverEvents.FullscreenDisabled, () => {
      this.$store.state.isNavBarVisible = true
      this.$store.state.isAsideVisible = true
      this.isFullScreenEnabled = false
      // update css
      document.documentElement.classList.add('has-aside-left', 'has-navbar-fixed-top')
    })

    Observer.Instance.subscribe(ObserverEvents.ModalDialogActivated, () => {
      this.setBodyScrollEnabled(1)
    })

    Observer.Instance.subscribe(ObserverEvents.ModalDialogDesactivated, () => {
      this.setBodyScrollEnabled(-1)
    })

    Observer.Instance.subscribe(ObserverEvents.ToggleExpandMenu, () => {
      // toggle aside menu expanded
      const expanded = LocalStorage.Instance.asideMenuExpanded
      LocalStorage.Instance.asideMenuExpanded = !expanded
      // propagate the change
      Observer.Instance.publish(ObserverEvents.MenuExpandStateChanged, !expanded)
      // update aside menu
      this.updateAsideMenu()
    })

    Observer.Instance.subscribe(ObserverEvents.ToggleDarknight, () => {
      // toggle dark mode
      LocalStorage.Instance.darkMode = !LocalStorage.Instance.darkMode
      // update theme
      this.updateDarkModeTheme()
    })

    Observer.Instance.subscribe(ObserverEvents.ServerConnectionError, () => {
      this.isConnectionError = true
    })
  }

  public mounted(): void {
    this.updateAsideMenu()
    this.updateDarkModeTheme()
    this.initPush()
  }

  private reconnect(): void {
    this.$router.go(0)
  }

  private updateDarkModeTheme(): void {
    const htmlClassName = 'is-dark-mode-active'
    if (LocalStorage.Instance.darkMode) {
      document.documentElement.classList.add(htmlClassName)
      document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#242424')
    } else {
      document.documentElement.classList.remove(htmlClassName)
      document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#ffffff')
    }
  }

  private updateAsideMenu(): void {
    const htmlClassName = 'has-aside-expanded'
    if (LocalStorage.Instance.asideMenuExpanded) {
      document.documentElement.classList.add(htmlClassName)
    } else {
      document.documentElement.classList.remove(htmlClassName)
    }
  }

  private setBodyScrollEnabled(count: number): void {
    // update the modal counter
    this.activeModalsCount += count
    // disable scroll if there is a modal active
    if (this.activeModalsCount === 1) {
      this.previousWindowScrollY = window.scrollY
      const safeAreaTop = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--sat').replace('px', ''))
      const navBar = (this.$refs.navBar as NavBar).$el
      const newTop = this.previousWindowScrollY > 0 ? (this.previousWindowScrollY - navBar.offsetHeight + safeAreaTop) : -navBar.offsetHeight
      const body = document.body
      body.style.top = `-${ newTop }px`
      body.classList.add('modal-dialog-active')
    }
    // enable the scroll if no active modals are active
    else if (this.activeModalsCount === 0) {
      const body = document.body
      body.classList.remove('modal-dialog-active')
      body.style.top = ''
      // restore scroll
      window.scrollTo(0, this.previousWindowScrollY)
    }
  }

  // Push Notifications

  private initPush(): void {
    if (Capacitor.isNativePlatform()) {

      // Request permission to use push notifications
      // iOS will prompt user and return if they granted permission or not
      // Android will just grant without prompting
      PushNotifications.requestPermissions().then(result => {
        if (result.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register()
          // Create a default channel
          PushNotifications.createChannel({
            id: DEFAULT_PUSH_CHANNEL,
            name: 'Defaut Channel Panel',
            importance: 3,
            visibility: 1,
            vibration: true,
          })
        }
      })

      // On success, we should be able to receive notifications
      PushNotifications.addListener('registration', async (/*token: Token*/) => {
        Server.Instance.registerDevice((await FCM.getToken()).token)
      })
      /*
            // Some issue with our setup and push will not work
            PushNotifications.addListener('registrationError', (error: unknown) => {
              alert('Error on registration: ' + JSON.stringify(error))
            })
      */

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener('pushNotificationReceived', async (notification: PushNotificationSchema) => {
        // display a local notification (using a local notification)
        LocalNotifications.schedule({
          notifications: [{
            title: notification.title ?? '',
            body: notification.body ?? '',
            sound: 'default',
            id: Date.now(),
            extra: notification,
            smallIcon: 'res://ic_stat_name',
            channelId: DEFAULT_PUSH_CHANNEL,
          }],
        })
        //this.executeNotification(notification)
      })

      // Method called when tapping on a notification
      PushNotifications.addListener('pushNotificationActionPerformed', (action: ActionPerformed) => {
        //alert('Push action performed: ' + JSON.stringify(action))

        // BACKGROUND

        this.executeNotification(action.notification)
      })

      LocalNotifications.addListener('localNotificationActionPerformed', (event: LocalActionPerformed) => {
        this.executeNotification(event.notification.extra)
      })
    }
  }

  private executeNotification(notification: PushNotificationSchema): void {
    const action = notification.data.action ?? null
    switch (action) {
      case PushActions.OrderCreated:
      case PushActions.OrderChanged: {
        const orderId = notification.data.order ?? ''
        this.$router.push({ name: 'ActiveOrders', query: { view: orderId } })
        break
      }
    }
  }
}
</script>

<style lang="scss">
@import "assets/styles/style.scss";

.modal-dialog-active {
  position: fixed;
  width: 100%;
}

.update-view {
  background: rgba($color: #fff, $alpha: 0.95);
  z-index: 1000;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

:root {
  --sat: env(safe-area-inset-top);
}
</style>
