import { SubscriptionDetails } from "./SubscriptionDetails"

export class SubscriptionPlanItem {
    public plan!: string
    public price!: string
    public priceInt!: number

    public get planStr(): string {
        const conv = new SubscriptionDetails();
        conv.plan = this.plan
        return conv.planStr
    }
}