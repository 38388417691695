import { Observer, ObserverEvents } from '@/models/Observer'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    redirect: '/orders/active',
    //component: () => import('../views/Home.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login/Login.vue')
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: () => import('../views/SignUp/SignUp.vue')
  },
  {
    path: '/dishes',
    name: 'Dishes',
    component: () => import('../views/Dishes/List.vue')
  },
  {
    path: '/dishes/editor/:id?',
    name: 'DishesEditor',
    component: () => import('../views/Dishes/Editor.vue')
  },
  {
    path: '/categories',
    name: 'Categories',
    component: () => import('../views/Categories/List.vue')
  },
  {
    path: '/categories/editor/:id?',
    name: 'CategoriesEditor',
    component: () => import('../views/Categories/Editor.vue')
  },
  {
    path: '/allergens',
    name: 'Allergens',
    component: () => import('../views/Allergens/List.vue')
  },
  {
    path: '/allergens/editor/:id?',
    name: 'AllergensEditor',
    component: () => import('../views/Allergens/Editor.vue')
  },
  {
    path: '/menus',
    name: 'Menus',
    component: () => import('../views/Menus/List.vue')
  },
  {
    path: '/menus/editor/:id?',
    name: 'MenusEditor',
    component: () => import('../views/Menus/Editor.vue')
  },
  {
    path: '/fixed-menus',
    name: 'FixedMenus',
    component: () => import('../views/FixedMenus/List.vue')
  },
  {
    path: '/fixed-menus/editor/:id?',
    name: 'FixedMenusEditor',
    component: () => import('../views/FixedMenus/Editor.vue')
  },
  {
    path: '/tables',
    name: 'Tables',
    component: () => import('../views/Tables/List.vue')
  },
  {
    path: '/tables/editor/:id?',
    name: 'TablesEditor',
    component: () => import('../views/Tables/Editor.vue')
  },
  {
    path: '/orders/active',
    name: 'ActiveOrders',
    component: () => import('../views/Orders/ActiveOrders.vue')
  },
  {
    path: '/orders/finished',
    name: 'FinishedOrders',
    component: () => import('../views/Orders/FinishedOrders.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/Profile/Profile.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../views/Settings/Settings.vue'),
  },
  {
    path: '/settings/landing/subscription/:action/:token?',
    name: 'SubscriptionRedirect',
    component: () => import('../views/Settings/SubscriptionRedirect.vue')
  },
  {
    path: "/:catchAll(.*)",
    component: () => import('../views/NotFound.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(savedPosition ?? { left: 0, top: 0 })
      }, 50)
    })
  }
})

router.beforeEach(async (to, from, next) => {
  // give an oportunity to cancel this "change"
  await Observer.Instance.publish(ObserverEvents.BeforeRouteLeave, next)
  // ok, continue as normal
  next(true)
});

export default router
