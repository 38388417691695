import { Entity } from '@/models/Entities/Entity'

export class Allergen extends Entity {
  public title!: string
  public description!: string
  public pictogramId = 0

  public pictogramUrl(): string {
    const id = (this.pictogramId < 10 ? '0' : '') + this.pictogramId
    return require(`@/assets/images/allergens/${id}.svg`);
  }
}