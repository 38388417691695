<template>
  <div class="modal" :class="{ 'is-active': visible }">
    <div
      class="modal-background animate__animated"
      :class="backgroundAnimation"
    ></div>
    <div
      class="modal-content has-text-centered animate__animated"
      :class="cardAnimation"
    >
      <upgrade-plan
        class="m-4"
        :title="title"
        :description="description"
        :is-embeded="true"
        :laterButton="true"
        @option-selected="close"
      />
    </div>
  </div>
</template>

<script lang="ts">

import { Observer, ObserverEvents } from '@/models/Observer'
import { Options, Vue } from 'vue-class-component'
import UpgradePlan from "@/components/UpgradePlan.vue"

@Options({
  props: {
  },
  emits: [
  ],
  components: {
    UpgradePlan,
  },
})
export default class UpgradePlanDialog extends Vue {
  private visible = false
  private title = ''
  private description = ''
  private resolve!: CallableFunction

  private hideTimeout = 0
  private backgroundAnimation = ''
  private cardAnimation = ''

  private static _instance: UpgradePlanDialog

  public static get Instance(): UpgradePlanDialog {
    return UpgradePlanDialog._instance
  }

  public created(): void {
    UpgradePlanDialog._instance = this
  }

  public ask(title: string, description: string): Promise<boolean | number> {
    // cancel the previous hide time-out
    if (this.hideTimeout > 0) {
      clearTimeout(this.hideTimeout)
    }
    // display the dialog
    this.visible = true
    this.title = title
    this.description = description
    // display animated
    this.backgroundAnimation = 'animate__fadeIn'
    this.cardAnimation = 'animate__fadeInUpSmall animate__faster'
    // publish this fact
    Observer.Instance.publish(ObserverEvents.ModalDialogActivated)
    // the response
    return new Promise<boolean | number>(resolve => {
      this.resolve = resolve
    });
  }

  public close(): void {
    // hide view
    this.backgroundAnimation = 'animate__fadeOut'
    this.cardAnimation = 'animate__fadeOutDownSmall animate__faster'
    // pubish this fact
    Observer.Instance.publish(ObserverEvents.ModalDialogDesactivated)
    this.hideTimeout = setTimeout(() => {
      this.visible = false
    }, 850)
  }
}

export function planUpgradeDialog(): UpgradePlanDialog {
  return UpgradePlanDialog.Instance
}
</script>

<style lang="scss" scoped>
.modal-card {
  width: 40vw !important;
}

@media screen and (max-width: 1200px) {
  .modal-card {
    width: 70vw !important;
  }
}

@media screen and (max-width: 900px) {
  .modal-card {
    width: 90vw !important;
  }
}

$padding: 1.45rem;

.modal-content {
  box-shadow: 0px 10px 30px 5px rgba($color: #000000, $alpha: 0.15);
  background-color: white !important;
  border-radius: 25px !important;
  padding: $padding !important;

  @media (max-width: 1024px) {
    width: 88%;
  }
}
</style>