<template>
  <section class="section hero is-fullheight is-error-section">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-mobile is-centered">
          <div class="column is-12-small-mobile is-9-mobile is-6-tablet is-5-desktop is-4-widescreen">
            <div class="logo">
              <img
                src="@/assets/images/common/logo.svg"
                class="pb-6 pl-5 pr-5"
                alt="Mr.Waiter"
              />
              <form v-on:submit.prevent="login()">
                <div class="field">
                  <label class="label">Email</label>
                  <div class="control has-icons-right">
                    <input
                      type="email"
                      autocomplete="on"
                      name="email"
                      required="required"
                      autofocus="autofocus"
                      class="input"
                      :class="{ 'is-danger': email === '' }"
                      v-model="email"
                      placeholder="Email con el que te registraste"
                    /><span
                    class="icon is-right has-text-danger"
                  ><i class="mdi mdi-alert-circle mdi-24px"></i
                  ></span>
                  </div>
                  <p
                    v-if="email === ''"
                    class="help is-danger"
                  >
                    Introduce el correo con el que te registraste
                  </p>
                </div>
                <div class="field">
                  <label class="label">Contraseña</label>
                  <div class="control is-clearfix">
                    <input
                      type="password"
                      autocomplete="on"
                      name="password"
                      required="required"
                      class="input"
                      :class="{ 'is-danger': password === '' }"
                      v-model="password"
                      placeholder="Contraseña de tu cuenta"
                    />
                  </div>
                  <p
                    v-if="password === ''"
                    class="help is-danger"
                  >
                    Introduce la contraseña de tu cuenta
                  </p>
                  <a
                    class="help"
                    @click="showOpsPasswordDialog()"
                  >¿No recuerdas la contraseña?</a>
                </div>
                <hr />
                <div class="field">
                  <div class="field-body">
                    <div class="field is-grouped">
                      <div class="control">
                        <button
                          type="submit"
                          class="button is-black"
                          @click="login()"
                          :disabled="!(email && password)"
                        >
                          Login
                        </button>
                      </div>
                      <a
                        class="is-size-6"
                        @click="goToSignUp()"
                      >¿No tienes ninguna cuenta creada?</a
                      >
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <modal-dialog ref="opsPasswordDialog">
    <template v-slot:content>
      <h1 class="has-text-weight-medium is-size-4 mb-3 animate__animated animate__fadeInUpSmall">
        ¿No recuerdas tu contraseña?
      </h1>
      <h2 class="has-text-grey is-size-6 mb-3 animate__animated animate__fadeInDown animate__fast">
        A todo el mundo le puede pasar, dinos tu email y te enviaremos un correo con los pasos a seguir para recuperar tu contraseña.
      </h2>
      <hr />
      <div class="field">
        <label class="label">Email con el que te registraste</label>
        <div class="control has-icons-right">
          <input
            type="email"
            autocomplete="on"
            name="email"
            required="required"
            autofocus="autofocus"
            class="input has-text-centered"
            :class="{ 'is-danger': email === '' }"
            v-model="email"
            placeholder="Email de la cuenta a recuperar"
          />
          <span class="icon is-right has-text-danger">
            <i class="mdi mdi-alert-circle mdi-24px"></i>
          </span>
        </div>
        <p
          v-if="email === ''"
          class="help is-danger"
        >
          Introduce el correo con el que te registraste
        </p>
      </div>
      <hr />
      <div class="buttons is-centered">
        <button
          class="button is-black"
          @click="sendPasswordRecovery()"
          :disabled="passwordSent"
        >Cambiar contraseña
        </button>
        <button
          class="button"
          @click="closeOpsPasswordDialog()"
          :disabled="passwordSent"
        >Cancelar
        </button>
      </div>
    </template>
  </modal-dialog>
</template>

<script lang="ts">

import { dialog } from '@/components/ModalConfirmation.vue'
import ModalDialog from '@/components/ModalDialog.vue'
import { LoginResultCode } from '@/models/LoginResult'
import { Observer, ObserverEvents } from '@/models/Observer'
import { Server } from '@/models/Server'
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Options({
  components: {
    ModalDialog,
  },
})
export default class LoginForm extends Vue {

  @Prop({ default: null }) public to!: string | null
  @Prop({ default: true }) public fullscreen!: boolean

  private email: string | null = null
  private password: string | null = null

  private passwordSent = false

  public get opsPasswordDialog(): ModalDialog {
    return this.$refs.opsPasswordDialog as ModalDialog
  }

  public beforeMount(): void {
    if (this.fullscreen) {
      Observer.Instance.publish(ObserverEvents.FullscreenEnabled)
    }
  }

  public beforeUnmount(): void {
    if (this.fullscreen) {
      Observer.Instance.publish(ObserverEvents.FullscreenDisabled)
    }
  }

  public login(): void {
    Server.Instance.login(this.email as string, this.password as string).then((result) => {
      if (result.code === LoginResultCode.Success) {
        if (this.to) this.$router.push(this.to)
        else this.$router.go(0)
      } else if (result.code === LoginResultCode.InvalidAuth)
        dialog().ask('El usuario y/o contraseña no son correctos', 'Asegurate de haber introducido correctamente tu correo y tu contraseña.', ['Vale'])
      else if (result.code === LoginResultCode.AccessForbidden)
        dialog().ask('Acceso prohibido', 'Tu cuenta ha sido suspendida y no se permite iniciar sesión.', ['Entiendo'])
      else dialog().ask('Error desconocido', 'Se ha producido un error y no se ha podido iniciar sesión, por favor, inténtalo más tarde.', ['Vale'])
    })
  }

  private async goToSignUp(): Promise<void> {
    this.$router.push({ name: 'SignUp' })
  }

  private showOpsPasswordDialog(): void {
    this.opsPasswordDialog.show()
  }

  private closeOpsPasswordDialog(): void {
    this.opsPasswordDialog.close()
  }

  private async sendPasswordRecovery(): Promise<void> {
    this.passwordSent = true
    await Server.Instance.rememberPassword(this.email as string)
    await dialog().ask('¡Correo enviado!', 'Si eres un usuario registrado recibirás un correo con los pasos a seguir.', ['Cerrar'])
    await this.closeOpsPasswordDialog()
  }
}
</script>

<style scoped>
</style>
