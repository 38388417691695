import { PhoneNumber } from '@/models/Entities/PhoneNumber'
import { Photo } from '@/models/Entities/Photo'
import { Server } from '@/models/Server'
import { TaxData } from '@/models/TaxData'
import { Type } from 'class-transformer'

export class AccountData {
  @Type(() => PhoneNumber) public phone!: PhoneNumber
  @Type(() => Photo) public logo!: Photo
  @Type(() => TaxData) public taxData: TaxData = new TaxData()
  public alias!: string
  public name!: string
  public email!: string
  public color!: string
  public publicUrl!: string

  public logoUrl(small: boolean): string {
    if (this.logo && !small) {
      return Server.Instance.resourceUrl('me/profile?nc=' + this.logo.id)
    }
    // customize color
    const color = this.color ? '&b=%23' + this.color : ''
    return 'https://api.dicebear.com/7.x/initials/svg?seed=' + encodeURI(this.name) + '&backgroundColorLevel=500&fontSize=37&bold=true' + color
  }

  public qr(): string {
    return Server.Instance.meQrPreview()
  }
}
