import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["modal", { 'is-active': _ctx.visible }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["modal-background animate__animated", _ctx.backgroundAnimation])
    }, null, 2),
    _createElementVNode("div", {
      class: _normalizeClass(["modal-content has-text-centered animate__animated", _ctx.cardAnimation])
    }, [
      _renderSlot(_ctx.$slots, "content", {}, undefined, true)
    ], 2)
  ], 2))
}